import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";

const DeleteQuestionModal = ({ question, onConfirm, onDeny }) => {
  return (
    <div
      className="modal"
      style={{
        display: "block",
        backgroundColor: "rgba(0, 0, 0, 0.5)",
        position: "fixed",
        top: "0",
        left: "0",
        width: "100%",
        height: "100%",
        zIndex: "1050",
        overflow: "hidden",
      }}
    >
      <div
        className="modal-dialog modal-dialog-centered"
        style={{
          maxWidth: "500px",
          margin: "auto",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <div
          className="modal-content"
          style={{
            backgroundColor: "rgb(32,35,43)",
            color: "#FFFFFF",
            borderRadius: "0",
          }}
        >
          <div
            className="modal-header"
            style={{
              justifyContent: "flex-start",
              padding: "1rem 1.5rem",
              fontSize: "1rem",
              display: "flex",
              alignItems: "center",
              border: "none",
            }}
          >
            <h5 className="modal-title">DELETE QUESTION</h5>
            <button
              type="button"
              className="close"
              onClick={onDeny}
              style={{
                color: "#FFFFFF",
                marginLeft: "auto",
                background: "none",
                border: "none",
                padding: "0",
              }}
            >
              <FontAwesomeIcon icon={faTimes} size="lg" />
            </button>
          </div>
          <div className="modal-body" style={{ padding: "1rem 1.5rem" }}>
            <p>Are you sure you want to delete this question?</p>
            <p style={{ color: "#fce552" }}>"{question.question}"</p>
          </div>
          <div
            className="modal-footer"
            style={{ border: "none", justifyContent: "flex-start" }}
          >
            <button
              type="button"
              className="btn"
              onClick={onDeny}
              style={{
                backgroundColor: "transparent",
                color: "#FFFFFF",
                marginRight: "10px",
              }}
            >
              CANCEL
            </button>
            <button
              type="button"
              className="btn"
              onClick={() => onConfirm(question)}
              style={{ backgroundColor: "transparent", color: "#fce552" }}
            >
              DELETE
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DeleteQuestionModal;
