import React, { useState } from "react";
import PropTypes from "prop-types";
import NormalMode from "../images/NormalMode.png";
import TwoXMode from "../images/TwoXMode.png";
import ThreeXMode from "../images/ThreeXMode.png";
import LetItRoll from "../images/LetItRoll.png";
import LetItRoll2 from "../images/LetItRollTwo.png";
import DegenMode from "../images/DegenMode.png";
import EdgeHomeMoneyLogo from "../images/EdgeHomeMoneyLogo.png";
import StartGame from "../images/startGameButton.png";
import apiClient from "../apiClient";

function QuestionMultiplierModal({
  onClose,
  selectedLimit,
  selectedQuestion,
  selectedFolder,
  questionData,
  onQuizCreated,
  create,
  ...props
}) {
  const modes = [
    {
      img: NormalMode,
      title: "Normal Mode",
      desc: "A short description",
      mode: "modeNormal",
    },
    {
      img: TwoXMode,
      title: "2X Mode",
      desc: "A short description",
      mode: "mode2X",
    },
    {
      img: ThreeXMode,
      title: "3X Mode",
      desc: "A short description",
      mode: "mode3X",
    },
    {
      img: LetItRoll,
      title: "Let It Roll",
      desc: "1st question",
      mode: "modeLetItRide",
    },
    {
      img: LetItRoll2,
      title: "Let It Roll",
      desc: "2nd question",
      mode: "modeLetItRide2",
    },
    {
      img: DegenMode,
      title: "Degen Mode",
      desc: "A short description",
      mode: "modeDegen",
    },
  ];
  // console.log(selectedQuestion, "selectedQuestion");
  // console.log(selectedFolder, "selectedFolder");
  const [selectedMode, setSelectedMode] = useState(null);

  const handleModeSelect = (index) => {
    console.log(modes[index].title);
    setSelectedMode(modes[index].mode);
  };

  async function createOrUpdateQuestion() {
    if (!selectedMode) {
      alert("Please select a mode before starting the game.");
      return;
    }

    const token = localStorage.getItem("token");
    console.log("creating or updating question");

    try {
      const response = await apiClient.post(
        "/v2/quiz",
        {
          id: selectedQuestion.id,
          mode: selectedMode,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      console.log(response.data);
      onQuizCreated(response.data.quiz);
    } catch (error) {
      console.log(error);
    }
  }
  return (
    <div
      className="d-flex justify-content-center align-items-center position-fixed top-0 start-0 w-100 h-100"
      style={{ background: "rgba(0, 0, 0, 0.5)", zIndex: 1050 }}
    >
      <div
        className="text-white rounded p-4 shadow-lg"
        style={{ width: "90%", maxWidth: "60rem", background: "#20242B" }}
      >
        <div className="d-flex justify-content-between align-items-center border-bottom border-gray pb-3 mb-3">
          <h2 className="h5 mb-0">{questionData.question}</h2>
          <button
            className="btn btn-close text-light"
            onClick={onClose}
            style={{ background: "none", border: "none", fontSize: "1.25rem" }}
          >
            ✕
          </button>
        </div>
        <div className="row g-3">
          {modes.map((item, index) => (
            <div
              key={index}
              className="col-4 text-center"
              onClick={() => handleModeSelect(index)}
            >
              <div
                className="p-3 h-100 d-flex flex-column justify-content-between"
                style={{
                  background: "#272A32",
                  cursor: "pointer",
                  borderBottom:
                    selectedMode === modes[index].mode
                      ? "2px solid yellow"
                      : "none", // Ensure bottom border only if selected
                }}
                onMouseEnter={(e) => {
                  e.currentTarget.style.border = "2px solid yellow"; // Border on all sides on hover
                }}
                onMouseLeave={(e) => {
                  if (selectedMode !== modes[index].mode) {
                    e.currentTarget.style.border = "none"; // Remove border if not selected
                  } else {
                    e.currentTarget.style.border = "none"; // Ensure no border
                    e.currentTarget.style.borderBottom = "2px solid yellow"; // Keep bottom border if selected
                  }
                }}
              >
                <div>
                  <img
                    src={item.img}
                    alt={item.title}
                    className="img-fluid mb-2"
                  />
                  <h3 className="h6 mb-0">{item.title}</h3>
                  <p className="small" style={{ color: "#A9AAAD" }}>
                    {item.desc}
                  </p>
                </div>
              </div>
            </div>
          ))}
        </div>
        <div className="d-flex justify-content-between align-items-center mt-4">
          <p
            className="mb-0 small d-flex align-items-center"
            style={{ color: "A6A7AA" }}
          >
            Cost to Play:{" "}
            <img
              src={EdgeHomeMoneyLogo}
              alt="Money Logo"
              style={{ height: "14px", marginLeft: "5px", marginRight: "5px" }}
            />
            <span className="text-warning">
              {props.session?.multiplier || selectedLimit}
            </span>
          </p>
          <button
            onClick={() => createOrUpdateQuestion()}
            disabled={!selectedMode}
            style={{
              background: "none",
              border: "none",
              cursor: selectedMode ? "pointer" : "not-allowed",
            }}
          >
            <img
              src={StartGame}
              alt="Start Game"
              style={{
                height: "100%",
                opacity: selectedMode ? 1 : 0.5,
              }}
            />
          </button>
        </div>
      </div>
    </div>
  );
}

QuestionMultiplierModal.propTypes = {
  onClose: PropTypes.func.isRequired,
};

export default QuestionMultiplierModal;
