import WalletConnectProvider from "@walletconnect/web3-provider";
import CoinbaseWalletSDK from "@coinbase/wallet-sdk";
import Web3Modal from "web3modal";

const providerOptions = {
	injected: {
		package: null,
		options: {
			darkMode: true,
		},
	},
	walletconnect: {
		package: WalletConnectProvider, // required
		options: {
			infuraId: "79bfc0e81e9e4f0cb4cc5822af1d7af3", // required
			darkMode: true,
		},
	},
	coinbasewallet: {
		package: CoinbaseWalletSDK, // Required
		options: {
			appName: "web3modal", // Required
			infuraId: "79bfc0e81e9e4f0cb4cc5822af1d7af3", // Required
			rpc: "", // Optional if `infuraId` is provided; otherwise it's required
			chainId: 1, // Optional. It defaults to 1 if not provided
			darkMode: true, // Optional. Use dark theme, defaults to false
		},
	},
};

let Web3ModalProvider;

if (typeof window !== "undefined") {
	Web3ModalProvider = new Web3Modal({
		lightboxOpacity: 0,
		network: "mainnet", // optional
		cacheProvider: false, // optional
		disableInjectedProvider: false,
		providerOptions, // required
		theme: "dark",
	});
} else {
	Web3ModalProvider = null;
}

export { Web3ModalProvider };
