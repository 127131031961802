import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons"; // Import the Font Awesome X icon

const DeleteFolderModal = ({ folder, onConfirm, onDeny }) => {
  const handleConfirm = () => {
    onConfirm(folder);
    onDeny();
  };

  return (
    <div
      className="modal"
      tabIndex="-1"
      role="dialog"
      style={{ display: "block", backgroundColor: "rgba(0, 0, 0, 0.5)" }}
    >
      <div className="modal-dialog modal-dialog-centered" role="document">
        <div
          className="modal-content"
          style={{
            backgroundColor: "rgb(32,35,43)",
            color: "#FFFFFF",
            borderRadius: "0",
          }}
        >
          <div
            className="modal-header"
            style={{
              justifyContent: "flex-start",
              padding: "1rem 1.5rem",
              fontSize: "1rem",
              display: "flex",
              alignItems: "center",
            }}
          >
            <h5 className="modal-title">Delete Folder</h5>
            <button
              type="button"
              className="close"
              onClick={onDeny}
              aria-label="Close"
              style={{
                color: "#FFFFFF",
                marginLeft: "auto",
                background: "none",
                border: "none",
                padding: "0",
              }}
            >
              <FontAwesomeIcon icon={faTimes} size="lg" />{" "}
              {/* Use Font Awesome icon here */}
            </button>
          </div>
          <div className="modal-body">
            <p>Are you sure you want to delete the folder "{folder.name}"?</p>
          </div>
          <div className="modal-footer" style={{ border: "none" }}>
            <button
              type="button"
              className="btn deny-btn"
              onClick={onDeny}
              style={{
                backgroundColor: "transparent",
                color: "#84868a",
                borderColor: "#84868a",
                border: "1px solid",
                borderRadius: "0",
              }}
            >
              Deny
            </button>
            <button
              type="button"
              className="btn confirm-btn"
              onClick={handleConfirm}
              style={{
                backgroundColor: "transparent",
                color: "#fce552",
                borderColor: "#fce552",
                border: "1px solid",
                borderRadius: "0",
                marginLeft: "10px",
              }}
            >
              Confirm
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DeleteFolderModal;
