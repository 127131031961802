import React, { useState } from "react";
import { FaArrowRight } from "react-icons/fa";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons"; // Import the Font Awesome X icon

const CreateNewGame = ({ folderData, onSave, onAnswerPage, onClose }) => {
  const [question, setQuestion] = useState("");
  const [selectedFolder, setSelectedFolder] = useState("");
  const [showFolderWarning, setShowFolderWarning] = useState(false);

  const handleSave = () => {
    if (!selectedFolder) {
      setShowFolderWarning(true);
      setTimeout(() => setShowFolderWarning(false), 5000);
      return;
    }

    const selectFolder = folderData.find(
      (folder) => folder.id === selectedFolder
    );
    onSave({ question, folder: selectFolder });
    onAnswerPage();
  };

  return (
    <div
      className="modal"
      tabIndex="-1"
      role="dialog"
      style={{ display: "block", backgroundColor: "rgba(0, 0, 0, 0.5)" }}
    >
      <div className="modal-dialog modal-dialog-centered" role="document">
        <div
          className="modal-content"
          style={{
            backgroundColor: "rgb(32,35,43)",
            color: "#FFFFFF",
            borderRadius: "0",
          }}
        >
          <div
            className="modal-Create"
            style={{
              justifyContent: "flex-start",
              padding: "1rem 1.5rem",
              fontSize: "1rem",
            }}
          >
            <h5 className="modal-title">CREATE NEW GAME</h5>
            <button
              type="button"
              className="close"
              onClick={onClose}
              aria-label="Close"
              style={{ color: "", marginLeft: "auto" }}
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            <div className="form-group">
              <label
                htmlFor="gameQuestion"
                style={{
                  color: "#84868a",
                  fontSize: "12px",
                  marginBottom: "5px",
                }}
              >
                ENTER QUESTION
              </label>
              <input
                type="text"
                className="form-control"
                id="gameQuestion"
                value={question}
                onChange={(e) => setQuestion(e.target.value)}
                style={{
                  backgroundColor: "#141820",
                  color: "#FFFFFF",
                  border: "none",
                  borderRadius: "0",
                }}
              />
            </div>
            <div className="form-group" style={{ marginTop: "15px" }}>
              <label
                htmlFor="folderDropdown"
                style={{
                  color: "#84868a",
                  fontSize: "12px",
                  marginBottom: "5px",
                }}
              >
                ADD TO FOLDER
              </label>
              <select
                className="form-control"
                id="folderDropdown"
                value={selectedFolder}
                onChange={(e) => setSelectedFolder(e.target.value)}
                style={{
                  backgroundColor: "#2b2e36",
                  color: "#65686d",
                  border: "none",
                  borderRadius: "0",
                }}
              >
                <option value="">Choose folder</option>
                {folderData.map((folder) => (
                  <option key={folder.id} value={folder.id}>
                    {folder.name}
                  </option>
                ))}
              </select>
              {showFolderWarning && (
                <div style={{ color: "#ff0000", marginTop: "5px" }}>
                  Please add a folder.
                </div>
              )}
            </div>
          </div>
          <div className="modal-footer" style={{ border: "none" }}>
            <button
              type="button"
              className="btn d-flex align-items-center"
              onClick={handleSave}
              disabled={!question}
              style={{ backgroundColor: "transparent", color: "#fce552" }}
            >
              <span>ANSWERS</span>
              <FaArrowRight style={{ marginLeft: "5px" }} />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreateNewGame;
