import React from "react";
import NewFolder from "../images/NewFolder.png";
import Modal from "react-modal";
import Xicon from "../images/x-icon.png";
import EditBtn from "../images/edit-button.png";
import Hightlight from "../images/folderhighlight.png";
import { useEffect } from "react";
function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const foldercustomStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    backgroundColor: "#231f2e",
    width: "20%",
  },
};

export default function QuizFolders(props) {
  const [NewFolderIsOpen, setNewFolderIsOpen] = React.useState(false);
  const [folderName, setFolderName] = React.useState("");
  const [folderSelected, setFolderSelected] = React.useState(-1);
  const [EditFolderIsOpen, setEditFolderIsOpen] = React.useState(false);
  const [selectedFolder, setSelectedFolder] = React.useState(null);

  useEffect(() => {
    console.log(props);
  }, [props]);
  function closeNewFolderModal() {
    setNewFolderIsOpen(false);
  }

  function nameFolder(event) {
    const folder = event.target.value;
    console.log(folderName);
    setFolderName(folderName);
  }

  return (
    <>
      <div className="row folder-row-margin">
        <div className="row folder-edit-row">
          <div className="col-12">
            <p
              className={classNames(
                folderSelected === -1
                  ? "folder-highlight-text folder-hover folder-text folder-text-margin"
                  : "folder-highlight-text-none folder-hover folder-text"
              )}
              onClick={() => {
                props.changeAllQuizes(true);
                setFolderSelected(-1);
              }}
            >
              ALL QUIZZES
            </p>
          </div>
        </div>
        <img
          alt="highlight"
          className={classNames(
            folderSelected === -1
              ? "folder-highlight-allquizzes"
              : "folder-highlight-none"
          )}
          src={Hightlight}
        />
        <div className="row">
          {props.folderData.length === 0 ? (
            <div>No list</div>
          ) : (
            props.folderData.map((folder, index) => {
              return (
                <div className="row folder-edit-row">
                  <div
                    onClick={() => {
                      props.quizGetter(folder);
                      props.changeAllQuizes(false);
                      setFolderSelected(index);
                    }}
                    className="col-8 folder-text folder-text-margin "
                    key={index}
                  >
                    <p
                      className={classNames(
                        index === folderSelected
                          ? "folder-highlight-text folder-hover"
                          : "folder-highlight-text-none folder-hover"
                      )}
                    >
                      {folder.name.toUpperCase()}
                    </p>
                  </div>
                  <div
                    className="col-2 folder-edit-delete folder-hover"
                    onClick={() => {
                      setEditFolderIsOpen(true);
                      setSelectedFolder(folder.id);
                    }}
                  >
                    <img alt="editbtn" className="" src={EditBtn} />
                  </div>

                  <div
                    onClick={() => {
                      props.deleteFolder(folder.id);
                    }}
                    className="col-2 folder-edit-delete folder-xicon "
                  >
                    <img alt="xicon" className="" src={Xicon} />
                  </div>
                  <img
                    alt="highlight"
                    className={classNames(
                      index === folderSelected
                        ? "folder-highlight"
                        : "folder-highlight-none"
                    )}
                    src={Hightlight}
                  />
                </div>
              );
            })
          )}
        </div>
      </div>
      <div className="row">
        <div
          className="folder-wrapper"
          onClick={() => {
            setNewFolderIsOpen(true);
          }}
        >
          <img alt="newfolder" className="newfolder-icon" src={NewFolder} />
        </div>
      </div>

      <Modal
        isOpen={NewFolderIsOpen}
        style={foldercustomStyles}
        onRequestClose={() => setNewFolderIsOpen(false)}
      >
        <div className="modal-header">
          <div>FOLDER TITLE</div>
        </div>
        <input
          className="folder-input"
          type="text"
          value={folderName}
          onChange={(event) => setFolderName(event.target.value)}
        />

        <hr className="hrline"></hr>
        <div className="modal-body">
          <div className="row">
            <div className="col-6 cancel-text-align">
              <button
                className="cancel-selector btn btn-secondary confirm-color"
                onClick={() => {
                  console.log(folderName);
                  const folder = {
                    name: folderName,
                    contents: {},
                  };
                  props.createFolder(folder);
                  setNewFolderIsOpen(false);
                  setFolderName("");
                }}
              >
                Confirm
              </button>
            </div>
            <div className="col-6 cancel-text-align">
              <button
                className="cancel-selector btn btn-danger "
                onClick={() => {
                  setNewFolderIsOpen(false);
                  setFolderName("");
                }}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </Modal>

      <Modal
        isOpen={EditFolderIsOpen}
        style={foldercustomStyles}
        onRequestClose={() => setEditFolderIsOpen(false)}
      >
        <div className="modal-header">
          <div>EDIT TITLE</div>
        </div>
        <input
          className="folder-input"
          type="text"
          value={folderName}
          onChange={(event) => setFolderName(event.target.value)}
        />

        <hr className="hrline"></hr>
        <div className="modal-body">
          <div className="row">
            <div className="col-6 cancel-text-align">
              <button
                className="cancel-selector btn btn-secondary confirm-color"
                onClick={() => {
                  const object = { id: selectedFolder, name: folderName };
                  props.editFolder(object);
                  setEditFolderIsOpen(false);
                  setFolderName("");
                }}
              >
                Confirm
              </button>
            </div>
            <div className="col-6 cancel-text-align">
              <button
                className="cancel-selector btn btn-danger "
                onClick={() => {
                  setEditFolderIsOpen(false);
                  setFolderName("");
                }}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
}
