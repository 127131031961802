import React from "react";
import Begin from "./Begin.png";
import Logo from "./logo.png";
import supabase from "./config/supabaseClient";
import SignIn from "./Createuser";
import { useSession } from "./config/supabasecontext";

export default function ViewerBegin(props) {
	// function classNames(...classes) {
	//   return classes.filter(Boolean).join(" ");
	// }

	const { data: session, error } = supabase.auth.getSession();

	console.log(session);
	if (!session || !session.user) {
		return (
			<>
				<div className="viewerbegin-background d-flex justify-content-center align-items-center vh-100">
					<SignIn />
				</div>
			</>
		);
	} else {
		return <div>Welcome, {session.user.email}</div>;
	}
}

// {/* <div className="row ">
// <div className="col-12 text-center">
//   <input placeholder="Username"></input>
// </div>
// </div>
// <div className="row margin-top-10">
// <div className="col-12 text-center">
//   <input placeholder="Password"></input>
// </div>
// </div>
// <div className="row text-center margin-top-10">
// <div className="col-12 ">
//   <div className="on-hover" onClick={() => props.startTestTrue()}>
//     <img
//       alt="begin"
//       src={Begin}
//       className="viewerbegin-confirm-btn"
//     />
//     {/* <p className="begin-text">BEGIN</p> */}
//   </div>
// </div>
// </div> */}
