// react component
// Path: joystick-overlay/src/components/createAnswers.js
// Language: javascript
import React, { useEffect } from "react";
import SubmitAnsBtn1 from "../images/submitansbtn1.png";
import Logo from "../images/logo.png";
import Navbar from "./ConfigNavBar";

export default function EditAnswers(props) {
  let [answerChoices, setAnswerChoices] = React.useState([
    { text: "", correct: false },
  ]);
  const [correctAnswer, setCorrectAnswer] = React.useState(null);
  const [quizTime, setQuizTime] = React.useState(0);
  const [isCurrentSelected, setCurrentIsSelected] = React.useState(null);
  const [questionTitle, setQuestionTitle] = React.useState(null);
  const [checkedboxes, setCheckedBoxes] = React.useState({});
  const [originalFolderData, setOriginalFolderData] = React.useState([]);
  const toggleCheckbox = (boxIndex) => {
    setCheckedBoxes((prev) => {
      const newChecked = { ...prev };
      newChecked[boxIndex] = !prev[boxIndex];
      console.log(newChecked);

      return newChecked;
    });
  };
  useEffect(() => {
    console.log(checkedboxes);
  }, [checkedboxes]);
  const getCheckedBoxes = () => {
    // filter out the indexes of checked checkboxes
    const indexes = Object.keys(checkedboxes).filter(
      (index) => checkedboxes[index]
    );
    // return the corresponding folder objects for the checked checkboxes
    return indexes.map((index) => {
      return props.folderData[index];
    });
  };

  // Usage
  // const checked = getCheckedBoxes();
  // checked = [0, 2, 5]

  //useeffect to check boxIndex

  function addAnswer() {
    // if the answer choices array is less than 7, add a new answer choice
    if (answerChoices.length < 8) {
      setAnswerChoices([...answerChoices, { text: "", correct: false }]);
    }
  }

  function changeTitle(event) {
    const newTitle = event.target.value;
    console.log(newTitle);
    setQuestionTitle(newTitle);
  }

  function changeCorrectAnswer(index) {
    // console.log(index);
    // Log(index);
    // if the current correct answer is the same as the new correct answer, set the correct answer to -1
    if (correctAnswer === index) {
      setCorrectAnswer(-1);
      setCurrentIsSelected(-1);
      return;
    }
    setCorrectAnswer(index);
    setCurrentIsSelected(index);
  }
  function onAnswerChange(event, index) {
    const newAnswerChoices = [...answerChoices];
    newAnswerChoices[index].text = event.target.value;
    setAnswerChoices(newAnswerChoices);
  }

  function clearAnswers() {
    setAnswerChoices([{ text: "", correct: false }]);
  }

  function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
  }
  function expandInput(answers, correct_answer) {
    // for each item in input, append to an object
    let obj = [];
    for (let i = 0; i < answers.length; i++) {
      obj.push({ text: answers[i], correct: i == correct_answer });
    }
    return obj;
  }
  function findFolderInfo(id) {
    // find any folders that this quiz id is in (does props.folderData[n].contents[id] exist, if so we need to add it to a state)
    let _originalFolderData = {};
    console.log(props.folderData);
    for (let i = 0; i < props.folderData.length; i++) {
      if (props.folderData[i].contents[id]) {
        _originalFolderData[i] = true;
      }
    }

    // set our state
    setOriginalFolderData(_originalFolderData);

    // set our checkedboxes state
    setCheckedBoxes(_originalFolderData);
  }
  function removeQuestionFromFolder(folder, quizId) {
    let newFolder = { ...folder };
    if (newFolder.contents[quizId]) {
      delete newFolder.contents[quizId];
    }
    return newFolder;
  }
  useEffect(() => {
    console.log(props.quizData);
    // get the value of props.quizData.correct_answer, if it is null, set it to -1
    if (props.quizData.correct_answer == null) {
      props.quizData.correct_answer = -1;
    }
    const correctAnswer = props.quizData.correct_answer; // if there is no correct answer, set it to -1 since there is no correct answer
    setAnswerChoices(expandInput(props.quizData.answer_choices, correctAnswer));
    // if there is a correct answer, set the current selected to the correct answer
    if (correctAnswer != -1) {
      setCurrentIsSelected(correctAnswer);
    }
    setQuizTime(props.quizData.quiz_time);
    setQuestionTitle(props.quizData.question);
    findFolderInfo(props.quizData.id);
  }, [props.quizData]);
  function submitQuestion() {
    // TODO retool this for ws

    // validate that there are at least 2 answer choices, and that there is a correct answer, and that there is no empty answer choices
    // TODO - add validation/feedback for empty answer choices
    if (answerChoices.length < 2) {
      return;
    }
    // if (correctAnswer === null) {
    //   return;
    // }
    if (quizTime === 0) {
      return;
    }
    for (let i = 0; i < answerChoices.length; i++) {
      if (answerChoices[i].text === "") {
        return;
      }
    }

    // window.Twitch.ext.rig.log(answerChoices);
    // if the validation passes, send the question to the backend
    // flatten answer choices into an array of strings
    const answerChoicesArray = answerChoices.map((answer) => answer.text);
    const finalAnswer = correctAnswer == -1 ? null : correctAnswer;
    // const questionTitle = props.quizData.question;
    console.log(questionTitle);
    const body = {
      quiz_time: quizTime,
      question: questionTitle,
      answer_choices: answerChoicesArray,
      correct_answer: finalAnswer,
      id: props.quizData.id,
    };
    console.log(body);
    props.updateQuestion(body);
    const folders = getCheckedBoxes();
    // loop for each folder
    for (let i = 0; i < props.folderData.length; i++) {
      if (checkedboxes[i] != originalFolderData[i] && checkedboxes[i] == true) {
        // send the quiz object to the backend
        props.addQuestionToFolder(props.quizData.id, props.folderData[i].id);
      } else if (
        checkedboxes[i] != originalFolderData[i] &&
        checkedboxes[i] == false
      ) {
        // remove the quiz out of the folderContents
        console.log("removing question from folder " + props.folderData[i].id);
        props.editFolder(
          removeQuestionFromFolder(props.folderData[i], props.quizData.id)
        );
      }
    }
    props.editQuiz(false);
  }
  return (
    <div className="background-quiz-selection">
      <Navbar homeClick={props.homeClick} statsClick={props.statsClick} />
      <div className="row">
        <div className="col-8 createanswer-scroll createanswer-scrollbar">
          <div className="row row-margin1">
            <div className="col-4">
              <p className="white-p enter-answers-p">EDIT MODE</p>
            </div>
            <div className="col-3">
              <p
                className="white-p p-margin2 clear-answers-text"
                onClick={clearAnswers}
              >
                CLEAR ALL
              </p>
            </div>
          </div>
          <div className="row row-margin2">
            <div className="col-10  enter-answer-box">
              <input
                className="input-answer"
                type="text"
                placeholder="ENTER QUESTION"
                value={questionTitle}
                onChange={(event) => changeTitle(event)}
              />
            </div>
          </div>
          {answerChoices.map((answerChoice, index) => {
            return (
              <div className="row row-margin2" key={index}>
                <div className="col-10  enter-answer-box">
                  <input
                    className="input-answer"
                    type="text"
                    placeholder="ENTER ANSWER"
                    value={answerChoice.text}
                    onChange={(event) => onAnswerChange(event, index)}
                  />
                </div>
                <div className="col-1 display-none">
                  <div
                    onClick={() => {
                      changeCorrectAnswer(index);
                    }}
                    value={index}
                    className={classNames(
                      index == isCurrentSelected ? " type5" : "type4",
                      "circleBase col-4"
                    )}
                  >
                    {" "}
                    <div
                      className={classNames(
                        index == isCurrentSelected ? " type7" : "type1",
                        "circleBase1"
                      )}
                    ></div>
                  </div>
                </div>
              </div>
            );
          })}
          <div className="row row-margin3">
            <div className="col-10">
              <button className="add-answer-button" onClick={addAnswer}>
                add more +
              </button>
            </div>
          </div>
        </div>

        <div className="col-4 time-to-answer-margin">
          <div className="row row-margin1">
            <div className="col-12 time-to-answer ">
              <p className="time-to-answer-text">TIME TO ANSWER (seconds)</p>
            </div>
          </div>
          <div className="row">
            <div className="col-12 input-time-align">
              <input
                className="input-time "
                type="text"
                value={quizTime}
                onChange={(e) => setQuizTime(e.target.value)}
              />
            </div>
          </div>
          <div className="add-to-section-edit">
            <div className="row add-to-row">
              <div className="col-12">
                <p className="add-to-folder-text">ADD TO / REMOVE</p>
              </div>
            </div>
            {props.folderData.map((folder, index) => {
              return (
                <div className="row checkbox-row">
                  <div className="col-1">
                    <label className="checkbox-margin">
                      <input type="checkbox" checked={checkedboxes[index]} />
                      <span
                        onClick={() => {
                          console.log("here");
                          toggleCheckbox(index);
                        }}
                      ></span>
                    </label>
                  </div>
                  <div className="col-11">
                    <p className="folder-text folder-text-margin1">
                      {folder.name.toUpperCase()}
                    </p>
                  </div>
                </div>
              );
            })}
          </div>
          <div className="row edit-answer-btn-row">
            <div className="col-6 quiz-select-new-config quiz-selection-top-margin">
              <section
                onClick={() => {
                  props.editQuiz(false);
                }}
                className="start-stop-buttons pointer-class-start left-box-polygon end-quiz-btn-section"
              >
                <p className="new-question-config-txt">BACK</p>
              </section>
            </div>
            <div className="col-6 quiz-select-new-config quiz-selection-top-margin create-quiz-btn-div">
              <section
                onClick={() => submitQuestion()}
                className="start-stop-buttons pointer-class-new green-box right-box-polygon create-move"
              >
                <p className="new-question-config-txt">SAVE QUIZ</p>
              </section>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

{
  /* <div className="col-3 time-to-answer-margin">
          <div className="row row-margin1">
            <div className="col-12 time-to-answer ">
              <p>TIME TO ANSWER (seconds)</p>
            </div>
          </div>
          <div className="row">
            <div className="col-12 input-time-align">
              <input
                className="input-time "
                type="text"
                value={quizTime}
                onChange={(e) => setQuizTime(e.target.value)}
              />
            </div>
          </div>
          <div className="row ">
            <div className="col-12 submit-question-align">
              <button
                className="submit-button submitAnswers grayscale-img"
                onClick={() => submitQuestion()}
              >
                <img
                  src={SubmitAnsBtn1}
                  className="submit-ans-img"
                  alt="submit"
                />
              </button>
              <div className="row">
                <div className="col-12 go-back-center">
                  <p
                    onClick={() => {
                      props.editQuiz(false);
                    }}
                    className="go-back-text-free"
                  >
                    &#60; GO BACK
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div> */
}
