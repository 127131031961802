import React from "react";
import { useEffect } from "react";
import Logo from "../images/logo.png";
import Vertperp from "../images/vertpurplebar.png";
import ButtonHover1 from "../images/buttonhover1.png";
import AnswerTime from "./AnswerTime";
import People from "../images/statspeople.png";
import StatsPercent from "./statsQuestionPercent";
import dailytrophy from "../images/dailytrophy.png";
import monthlytrophy from "../images/monthlytrophy.png";
import Hoticon from "../images/hoticon.png";
import Coldicon from "../images/coldicon.png";
import Navbar from "./ConfigNavBar";

export default function StreamerPage(props) {
  const [showQuestionPercent, setShowQuestionPercent] = React.useState(false);
  const [selectedQuestion, setSelectedQuestion] = React.useState({});
  const [segmentType, setSegmentType] = React.useState("daily");

  // useEffect
  useEffect(() => {
    console.log(props.leaderboard);
    props.requestClientUpdate();
  }, []);
  function openQuestionPercent() {
    setShowQuestionPercent(true);
  }

  function closeQuestionPercent() {
    setShowQuestionPercent(false);
  }

  if (showQuestionPercent == false) {
    return (
      <>
        <div>
          <div className="row">
            <Navbar homeClick={props.homeClick} statsClick={props.statsClick} />
          </div>
          <div className="row stats-page-container">
            <div className="col-9 background-quiz-selection stats-question-col ">
              <div className="row stats-selectrow-config  ">
                {/* put refresh button up here */}
                <div className="row qa-refresh-row">
                  <div className="col-6 q-a-text">Q&A'S</div>
                  <div
                    className="edit-refresh-btn col-6"
                    onClick={() => {
                      props.requestClientUpdate();
                    }}
                  >
                    <svg
                      className="refresh-icon"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 30 30"
                      width="30px"
                      height="30px"
                    >
                      <path d="M 15 3 C 12.031398 3 9.3028202 4.0834384 7.2070312 5.875 A 1.0001 1.0001 0 1 0 8.5058594 7.3945312 C 10.25407 5.9000929 12.516602 5 15 5 C 20.19656 5 24.450989 8.9379267 24.951172 14 L 22 14 L 26 20 L 30 14 L 26.949219 14 C 26.437925 7.8516588 21.277839 3 15 3 z M 4 10 L 0 16 L 3.0507812 16 C 3.562075 22.148341 8.7221607 27 15 27 C 17.968602 27 20.69718 25.916562 22.792969 24.125 A 1.0001 1.0001 0 1 0 21.494141 22.605469 C 19.74593 24.099907 17.483398 25 15 25 C 9.80344 25 5.5490109 21.062074 5.0488281 16 L 8 16 L 4 10 z" />
                    </svg>
                  </div>
                </div>
                <div className="row question-info-row">
                  {props.quizData != null &&
                    props.quizData.map((quiz, index) => {
                      let classString = quiz.active
                        ? "stats-overall-section stats-open"
                        : "stats-overall-section stats-open";
                      return (
                        <div
                          className="col-12 col-md-12 col-lg-12 "
                          key={index}
                        >
                          <section
                            className={classString}
                            onClick={() => {
                              setSelectedQuestion(quiz);
                              openQuestionPercent();
                              console.log(showQuestionPercent);
                            }}
                          >
                            <div className="row">
                              <div className="row">
                                <div className="col-8">
                                  <p className="quiz-question-text-stats">
                                    {quiz.question}
                                  </p>
                                </div>
                                <div className="col-4 text-center total-people-font-size">
                                  {quiz.answer_distribution.total_answers}
                                  <span className="stats-correct-answer">
                                    {" "}
                                    <span className="parenthesis-color">
                                      &#40;
                                    </span>
                                    {quiz.active
                                      ? "0"
                                      : quiz.answer_distribution
                                          .answer_distribution[
                                          quiz.correct_answer
                                        ]}
                                  </span>{" "}
                                  &#47;{" "}
                                  <span className="stats-wrong-answer">
                                    {quiz.active
                                      ? "0"
                                      : quiz.answer_distribution.total_answers -
                                        quiz.answer_distribution
                                          .answer_distribution[
                                          quiz.correct_answer
                                        ]}
                                    <span className="parenthesis-color">
                                      &#41;
                                    </span>
                                  </span>{" "}
                                  <img alt="people" src={People} />
                                </div>
                              </div>
                              <div className="row timer-stats">
                                {quiz.active ? (
                                  <div className=" answer-time-stats">
                                    <AnswerTime
                                      endtime={quiz.end_time}
                                      raw={true}
                                    ></AnswerTime>
                                  </div>
                                ) : (
                                  <div className="stats-closed-question">
                                    CLOSED
                                  </div>
                                )}
                              </div>
                            </div>
                          </section>
                        </div>
                      );
                    })}
                </div>
              </div>
            </div>
            <div className="col-3 background-leader-board">
              <div className="row ">
                <div className="row leaderboard-stats-position ">
                  <div class="col-4 leaderboard-tab-transparent"></div>
                  <div className="col-8 g-0 leaderboard-tab-col">
                    <div className="row g-0 leaderboard-tab-wrapper">
                      <div
                        className={
                          segmentType == "daily"
                            ? "col-6 g-0 leaderboard-tab-active"
                            : "col-6 g-0 leaderboard-tab"
                        }
                        onClick={() => {
                          setSegmentType("daily");
                        }}
                      >
                        <div className="leaderboard-tab-img">
                          <img alt="dailytrophy" src={dailytrophy} />
                        </div>
                      </div>
                      <div
                        className={
                          segmentType == "monthly"
                            ? "col-6 g-0 leaderboard-tab-active"
                            : "col-6 g-0 leaderboard-tab"
                        }
                        onClick={() => {
                          setSegmentType("monthly");
                        }}
                      >
                        <div className="leaderboard-tab-img">
                          <img alt="monthlytrophy" src={monthlytrophy} />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="stats-leaderboard-section leaderboard-text">
                      LEADERBOARD
                    </div>
                    <p className="leaderboard-menu-title stats-leaderboard-menu-title">
                      {segmentType.toUpperCase()}
                    </p>
                  </div>
                </div>
                {
                  // check if daily is in the leaderboard object and if it has any players
                  props.leaderboard != null ? (
                    props.leaderboard[segmentType] != null &&
                    props.leaderboard[segmentType].length > 0 ? (
                      props.leaderboard[segmentType].map((player, index) => {
                        return (
                          <div className="row stats-leaderboard-players ">
                            <div className="col-12">
                              <div
                                className="stats-leaderboard-section leaderboard-text"
                                key={index}
                              >
                                {player.display_name}
                              </div>
                              <div className="row">
                                <div className="col-8 stats-leaderboard-section leaderboard-text stats-player-points">
                                  {player.pointTotal}
                                </div>
                                <div className="col-2 hot-cold-wrapper">
                                  {player.hot_streak != null &&
                                  player.cold_streak != null ? (
                                    (player.hot_streak >= 3 && (
                                      <img
                                        alt="hoticon"
                                        className="hot-cold-icon"
                                        src={Hoticon}
                                      />
                                    )) ||
                                    (player.cold_streak >= 3 && (
                                      <img
                                        alt="coldicon"
                                        className="hot-cold-icon"
                                        src={Coldicon}
                                      />
                                    ))
                                  ) : (
                                    <></>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        );
                      })
                    ) : (
                      <div className="stats-leaderboard-section">
                        No players have scored for the current time period
                      </div>
                    )
                  ) : (
                    <div className="stats-leaderboard-section">
                      No leaderboard data available
                    </div>
                  )
                }
              </div>
            </div>
          </div>
        </div>
      </>
    );
  } else if (showQuestionPercent == true) {
    return (
      <StatsPercent
        {...props}
        selectedQuestion={selectedQuestion}
        closeQuestionPercent={closeQuestionPercent}
      />
    );
  }
}
