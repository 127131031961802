import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes, faTrash } from "@fortawesome/free-solid-svg-icons";

export default function EditQuestionModal({
  question,
  onSave,
  onClose,
  folders,
}) {
  const [answerChoices, setAnswerChoices] = useState(
    question.answer_choices.map((text) => ({ text, correct: false }))
  );
  const [correctAnswer, setCorrectAnswer] = useState(question.correct_answer);
  const [quizTime, setQuizTime] = useState(question.quiz_time);
  const [isCurrentSelected, setCurrentIsSelected] = useState(
    question.correct_answer
  );
  const [questionText, setQuestionText] = useState(question.question);

  function addAnswer() {
    if (answerChoices.length < 8) {
      setAnswerChoices([...answerChoices, { text: "", correct: false }]);
    }
  }

  function deleteAnswer(index) {
    const newAnswerChoices = answerChoices.filter((_, i) => i !== index);
    setAnswerChoices(newAnswerChoices);
    if (index === correctAnswer) {
      setCorrectAnswer(null);
      setCurrentIsSelected(null);
    } else if (index < correctAnswer) {
      setCorrectAnswer(correctAnswer - 1);
      setCurrentIsSelected(correctAnswer - 1);
    }
  }

  function changeCorrectAnswer(index) {
    setCorrectAnswer(index);
    setCurrentIsSelected(index);
  }

  function onAnswerChange(event, index) {
    const newAnswerChoices = [...answerChoices];
    newAnswerChoices[index].text = event.target.value;
    setAnswerChoices(newAnswerChoices);
  }

  function clearAnswers() {
    setAnswerChoices([{ text: "", correct: false }]);
  }

  function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
  }

  function submitQuestion() {
    if (answerChoices.length < 2) return;
    if (quizTime === 0) return;
    for (let i = 0; answerChoices.length; i++) {
      if (answerChoices[i].text === "") return;
    }

    const answerChoicesArray = answerChoices.map((answer) => answer.text);

    const updatedQuestion = {
      ...question,
      quiz_time: quizTime,
      question: questionText,
      answer_choices: answerChoicesArray,
      correct_answer: correctAnswer,
    };
    console.log(updatedQuestion);
    onSave(updatedQuestion);
  }

  return (
    <div
      className="modal"
      style={{
        display: "block",
        backgroundColor: "rgba(0, 0, 0, 0.5)",
        position: "fixed",
        top: "0",
        left: "0",
        width: "100%",
        height: "100%",
        zIndex: "1050",
        overflow: "hidden",
      }}
    >
      <div
        className="modal-dialog modal-dialog-centered"
        style={{
          maxWidth: "80%",
          margin: "auto",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <div
          className="modal-content"
          style={{
            backgroundColor: "rgb(32,35,43)",
            color: "#FFFFFF",
            borderRadius: "0",
          }}
        >
          <div
            className="modal-header"
            style={{
              justifyContent: "flex-start",
              padding: "1rem 1.5rem",
              fontSize: "1rem",
              display: "flex",
              alignItems: "center",
              border: "none",
            }}
          >
            <h5 className="modal-title">EDIT QUESTION</h5>
            <button
              type="button"
              className="close"
              onClick={onClose}
              style={{
                color: "#FFFFFF",
                marginLeft: "auto",
                background: "none",
                border: "none",
                padding: "0",
              }}
            >
              <FontAwesomeIcon icon={faTimes} size="lg" />
            </button>
          </div>
          <div className="modal-body" style={{ padding: "1rem 1.5rem" }}>
            <div className="row">
              <div className="col-12 mb-3">
                <input
                  className="form-control"
                  type="text"
                  placeholder="ENTER QUESTION"
                  value={questionText}
                  onChange={(e) => setQuestionText(e.target.value)}
                  style={{
                    backgroundColor: "#141820",
                    color: "#FFFFFF",
                    border: "none",
                    borderRadius: "0",
                  }}
                />
              </div>
              <div className="col-8 createanswer-scroll createanswer-scrollbar">
                <div className="row row-margin1">
                  <div className="col-4">
                    <p className="white-p enter-answers-p no-margin-bottom">
                      ENTER ANSWERS
                    </p>
                  </div>
                  <div className="col-3">
                    <p
                      className="white-p p-margin2 clear-answers-text no-margin-bottom"
                      onClick={clearAnswers}
                      style={{ cursor: "pointer", color: "#fce552" }}
                    >
                      CLEAR ALL
                    </p>
                  </div>
                </div>
                {answerChoices.map((answerChoice, index) => (
                  <div
                    className="row row-margin2 align-items-center"
                    key={index}
                  >
                    <div className="col-10 enter-answer-box">
                      <input
                        className="input-answer"
                        type="text"
                        placeholder="ENTER ANSWER"
                        onChange={(e) => onAnswerChange(e, index)}
                        value={answerChoice.text}
                        style={{
                          backgroundColor: "#141820",
                          color: "#FFFFFF",
                          border: "none",
                          borderRadius: "0",
                        }}
                      />
                    </div>
                    <div className="col-1 text-center">
                      <FontAwesomeIcon
                        icon={faTrash}
                        style={{ cursor: "pointer", color: "#FCE552" }}
                        onClick={() => deleteAnswer(index)}
                      />
                    </div>
                  </div>
                ))}
                <div className="row row-margin3">
                  <div className="col-10">
                    <button
                      className="add-answer-button"
                      onClick={addAnswer}
                      style={{
                        backgroundColor: "transparent",
                        color: "#fce552",
                        border: "none",
                      }}
                    >
                      add more +
                    </button>
                  </div>
                </div>
              </div>
              <div className="col-4 time-to-answer-margin">
                <div className="row row-margin1">
                  <div className="col-12 time-to-answer ">
                    <p className="time-to-answer-text">
                      TIME TO ANSWER (seconds)
                    </p>
                  </div>
                </div>
                <div className="row">
                  <div className="col-12 input-time-align">
                    <input
                      className="input-time"
                      type="text"
                      value={quizTime}
                      onChange={(e) => setQuizTime(e.target.value)}
                      style={{
                        backgroundColor: "#141820",
                        color: "#FFFFFF",
                        border: "none",
                        borderRadius: "0",
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            className="modal-footer"
            style={{ border: "none", justifyContent: "flex-start" }}
          >
            <button
              type="button"
              className="btn"
              onClick={onClose}
              style={{
                backgroundColor: "transparent",
                color: "#FFFFFF",
                marginRight: "10px",
              }}
            >
              CANCEL
            </button>
            <button
              type="button"
              className="btn"
              onClick={submitQuestion}
              style={{ backgroundColor: "transparent", color: "#fce552" }}
            >
              SAVE CHANGES
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
