import React, { useState } from "react";
import PropTypes from "prop-types";

// Placeholder imports for image buttons, replace these with actual image imports
import confirmEndImage from "../images/EndGameButtonEdge.png";
import confirmCancelImage from "../images/CancelGameButtonEdge.png";
import apiClient from "../apiClient";

function EndCancelModal({
	modalType,
	currentQuiz,
	onClose,
	handleConfirm,
	onCancel,
}) {
	let token = localStorage.getItem("token");
	const [isChecked, setIsChecked] = useState(false);
	const endQuiz = async () => {
		console.log("endQuiz");
		handleConfirm();
	};
	console.log(currentQuiz, "currentQuiz");
	const cancelQuiz = async () => {
		console.log("cancelQuiz");
		await apiClient
			.delete("/v1/quiz", {
				data: { id: currentQuiz.question_id },
				headers: {
					Authorization: `Bearer ${token}`,
				},
			})
			.then((response) => {
				console.log(response, "response");
				onCancel();
			})
			.catch((error) => {
				console.log(error, "error");
			});
	};
	const renderContent = () => {
		if (modalType === "end") {
			return (
				<div className="d-flex flex-column align-items-center">
					<div className="text-center mb-2">
						Are you sure this is the right answer?
					</div>
					<div className="text-center mb-2" style={{ color: "#A6A7AA" }}>
						{currentQuiz.question} ?
					</div>
					<h3 style={{ color: "#FCE552" }}>
						Option {currentQuiz.correct_answer}
					</h3>
					<div className="d-flex justify-content-between align-items-center w-100 my-3">
						<div className="form-check text-center">
							<input
								type="checkbox"
								className="form-check-input custom-checkbox"
								id="confirmCheckbox"
								checked={isChecked}
								onChange={() => setIsChecked(!isChecked)}
							/>
							<label className="form-check-label" htmlFor="confirmCheckbox">
								I confirm the action
							</label>
						</div>
						<button
							onClick={handleConfirm}
							style={{
								background: "none",
								border: "none",
								marginTop: "1rem",
							}}
							disabled={!isChecked}
						>
							<img
								// onClick={endQuiz}
								src={confirmEndImage}
								alt="Confirm End Game"
								className="img-fluid"
							/>
						</button>
					</div>
				</div>
			);
		} else if (modalType === "cancel") {
			return (
				<div className="d-flex flex-column align-items-center">
					<div className="text-center mb-2">
						Are you sure you want to cancel this game?
					</div>
					<h3 className="text-center mb-2" style={{ color: "#FCE552" }}>
						{currentQuiz.question} ?
					</h3>
					<div className="d-flex justify-content-between align-items-center w-100 my-3">
						<div className="form-check text-center">
							<input
								type="checkbox"
								className="form-check-input"
								id="confirmCheckbox"
								checked={isChecked}
								onChange={() => setIsChecked(!isChecked)}
							/>
							<label className="form-check-label" htmlFor="confirmCheckbox">
								I confirm the action
							</label>
						</div>
						<button
							onClick={onClose}
							style={{
								background: "none",
								border: "none",
								marginTop: "1rem",
							}}
							disabled={!isChecked}
						>
							<img
								onClick={cancelQuiz}
								src={confirmCancelImage}
								alt="Confirm Cancel"
								className="img-fluid"
							/>
						</button>
					</div>
				</div>
			);
		}
		return null;
	};

	return (
		<div
			className="d-flex justify-content-center align-items-center position-fixed top-0 start-0 w-100 h-100"
			style={{ background: "rgba(0, 0, 0, 0.8)", zIndex: 1050 }}
		>
			<div
				className="text-white rounded p-4 shadow-lg d-flex flex-column justify-content-between"
				style={{
					width: "90%",
					maxWidth: "40rem",
					background: "#20242B",
					height: "auto",
				}}
			>
				<div className="d-flex justify-content-between align-items-center pb-3 mb-3">
					<h2 className="h5 mb-0">Confirm Answers</h2>
					<button
						className="btn btn-close text-light"
						onClick={onClose}
						style={{ background: "none", border: "none", fontSize: "1.25rem" }}
					>
						✕
					</button>
				</div>
				{renderContent()}
			</div>
		</div>
	);
}

EndCancelModal.propTypes = {
	modalType: PropTypes.string.isRequired,
	currentQuiz: PropTypes.object.isRequired,
	onClose: PropTypes.func.isRequired,
	handleConfirm: PropTypes.func.isRequired,
};

export default EndCancelModal;
