import React, { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import SelectCreditLimit from "./SelectCreditLimit";
import EdgeHome from "./EdgeHome";

const PlayEdge = ({ streamerSession, ...props }) => {
	const { show, onHide } = props;

	const [showModal, setShowModal] = useState(true);
	const [selectedLimit, setSelectedLimit] = useState(null);

	useEffect(() => {
		if (streamerSession) {
			setShowModal(false);
		} else {
			setShowModal(true);
		}
	}, [streamerSession]);

	const handleCreditLimitSelect = (limit) => {
		setSelectedLimit(limit);
		setShowModal(false);
	};

	if (streamerSession) {
		// Render EdgeHome if there is an active session
		return <EdgeHome {...props} session={streamerSession} />;
	}

	if (selectedLimit) {
		// Render EdgeHome if a limit has been selected
		return <EdgeHome selectedLimit={selectedLimit} {...props} />;
	}

	return (
		<Modal show={showModal} onHide={onHide} size="lg" centered>
			<Modal.Body style={{ backgroundColor: "#333333" }}>
				<SelectCreditLimit onSelectLimit={handleCreditLimitSelect} />
			</Modal.Body>
		</Modal>
	);
};

export default PlayEdge;
