import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import EdgeHomeLogo from "../images/EdgeHomeLogo.png";
import EdgeHomeMoneyLogo from "../images/EdgeHomeMoneyLogo.png";
import apiClient from "../apiClient";

const EdgeHomeNavbar = ({ selectedLimit, ...props }) => {
  console.log(props, "props");
  console.log(selectedLimit, "selectedLimit");
  const deleteCurrentSession = async () => {
    try {
      const response = await apiClient.delete("/v1/stream_session", {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });
      if (response.data.success) {
        window.location.reload();
        console.log("Stream session deleted successfully");
      }
    } catch (error) {
      console.error("Error deleting current session", error);
    }
  };
  return (
    <nav
      style={{
        backgroundColor: "#13161F",
        color: "white",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        padding: "20px 20px",
      }}
    >
      <div style={{ display: "flex", alignItems: "center" }}>
        <button
          className="btn text-white"
          style={{
            backgroundColor: "transparent",
            border: "none",
            marginRight: "20px",
          }}
        >
          <i className="bi bi-list" style={{ fontSize: "1.5rem" }}></i>{" "}
          {/* Using Bootstrap icons here */}
        </button>
        <span style={{ fontSize: "1.2rem" }}>STATISTICS</span>
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <img src={EdgeHomeLogo} alt="EDGE Logo" style={{ height: "30px" }} />
        <span
          className="limit-highlight" // Add this class
          style={{
            margin: "10px 0",
            fontSize: "1rem",
            display: "flex",
            alignItems: "center",
          }}
        >
          LIMIT
          <img
            src={EdgeHomeMoneyLogo}
            alt="Money Logo"
            style={{ height: "14px", marginLeft: "5px", marginRight: "5px" }}
          />{" "}
          {selectedLimit ? selectedLimit : props.session.multiplier}
        </span>
      </div>
      <div>
        <button
          className="btn text-white"
          style={{ backgroundColor: "transparent", border: "none" }}
          onClick={deleteCurrentSession}
        >
          END EDGE <i className="bi bi-x-lg" style={{ fontSize: "1.5rem" }}></i>
        </button>
      </div>
    </nav>
  );
};

export default EdgeHomeNavbar;
