import React, { useState, useEffect } from "react";
import LoadingConfig from "./components/loadingconfig";
import StartQuizConfig from "./components/startquizconfig";
import QuizSelectionConfig from "./components/quizselectionconfig";
import CreateQuestionComponent from "./components/createQuestionComponent";
import ConfigDashboard from "./components/configDashboard";
import ChooseCorrectAnswer from "./components/chooseCorrectAnswer";
import EditAnswers from "./components/editAnswers";
import Stats from "./components/statsPage";
import Login from "./supabaseLogin";
import { useSession } from "./config/supabasecontext";
import { io } from "socket.io-client";
import apiClient from "./apiClient";
import supabase from "./config/supabaseClient";
import TwoFactorAuth from "./components/twoFactor";
import ConnectAccount from "./components/connectAccount";
import Dashboard from "./components/dashboard";
import Loading from "./components/LoadingState";
import NotVerifiedStreamerModal from "./components/NotVerifiedStreamerModal";
// let client;
// if (process.env.NODE_ENV === "development") {
// 	// client = io("wss://twitchbes.mindir.io");
// 	client = io("ws://localhost:5000");
// } else {
// 	client = io("ws://localhost:5000");
// 	// client = io("wss://twitchbes.mindir.io");
// }

export default function Config() {
	// const session = useSession();
	const [loading, setLoading] = React.useState(true);
	const [quiz, setQuiz] = React.useState(false);
	const [streamQuizData, setStreamQuizData] = React.useState([]);
	const [streamLeaderboard, setStreamLeaderboard] = React.useState([]);
	const [question, setQuestion] = React.useState("");
	const [questionData, setQuestionData] = React.useState([]);
	const [folderData, setFolderData] = React.useState([]);
	const [updateInterval, setUpdateInterval] = React.useState(null);
	const [dashIndex, setDashIndex] = React.useState(0);
	const [selectedQuestion, setSelectedQuestion] = React.useState(null);
	const [showCreateQuestion, setShowCreateQuestion] = React.useState(false);
	const [showChooseQuiz, setShowChooseQuiz] = React.useState(false);
	const [chooseQuizData, setChooseQuizData] = React.useState(null);
	const [editQuizData, setEditQuizData] = React.useState(null);
	const [showStats, setShowStats] = React.useState(null);
	const [respondants, setRespondants] = React.useState({});
	const [authToken, setauthToken] = useState(null);
	const [parentSession, setParentSession] = useState(null);
	const [sessionLoaded, setSessionLoaded] = useState(false);
	const [currentStreamer, setCurrentStreamer] = useState(null);
	const [streamerName, setStreamerName] = useState("");
	const [verifiedStreamer, setVerifiedStreamer] = useState(true);

	function moveToShowCreateQuestion(val) {
		if (val === true) {
			setDashIndex(1);
		}
		setShowCreateQuestion(val);
	}

	function updateQuestionList(incomingQuestionData) {
		// console.log(incomingQuestionData);
		let newQuestionData = [...incomingQuestionData];

		newQuestionData.sort((a, b) => {
			return a.order - b.order;
		});

		newQuestionData.sort((a, b) => {
			return b.active_quiz - a.active_quiz;
		});

		setQuestionData(newQuestionData);
		return;
	}

	function updateFoldersList(incomingFolderData) {
		// console.log(incomingFolderData);
		let newFolderData = [...incomingFolderData];
		newFolderData.sort((a, b) => {
			return a.position - b.position;
		});
		setFolderData(newFolderData);
		return;
	}

	function DashIndexSetter(val) {
		setDashIndex(val);
	}

	function SelectedQuestionSetter(val) {
		// console.log("Selected question set to: " + val);
		setSelectedQuestion(val);
	}

	function getShowCreateQuestion() {
		return showCreateQuestion;
	}

	function updateLeaderboardCallback(localToken = null) {
		// console.log(currentStreamer, "currentStreamer");
		const token = localToken || authToken;
		// console.log("refreshing");
		apiClient
			.get("/chrome/leaderboard", {
				headers: {
					Authorization: `Bearer ${token}`,
					channel_username: currentStreamer,
				},
			})
			.then((response) => {
				// console.log(response.data);
				console.log("updateCallbackLeaderboard Done");
				setStreamLeaderboard(response.data.leaderboard);
			})
			.catch((error) => {
				console.log(error);
			});
	}

	function updateQuestionsCallback(localToken = null) {
		const token = localToken || authToken;
		// console.log("refreshing");
		// console.log(token, "testingtoken");
		apiClient
			.get("/v1/questions", {
				headers: {
					Authorization: `Bearer ${token}`,
				},
			})
			.then((response) => {
				// console.log("updateCallbackQuestions Done");
				console.log(response.data);
				updateQuestionList(response.data);
			})
			.catch((error) => {
				console.log(error);
			});
	}

	function updateQuizsCallback(localToken = null) {
		const token = localToken || authToken;
		// console.log("refreshing");
		apiClient
			.get("/v1/streamer/quiz", {
				headers: {
					Authorization: `Bearer ${token}`,
					channel_username: currentStreamer,
				},
			})
			.then((response) => {
				// console.log("updateCallbackQuizs Done");
				console.log(response.data);
				setStreamQuizData(response.data.quizzes);
			})
			.catch((error) => {
				console.log(error);
			});
	}

	function updateFoldersCallback(localToken = null) {
		const token = localToken || authToken;
		// console.log("refreshing");
		apiClient
			.get("/v1/folders", {
				headers: {
					Authorization: `Bearer ${token}`,
					channel_username: currentStreamer,
				},
			})
			.then((response) => {
				// console.log("updateCallbackFolders Done");
				console.log(response.data);
				updateFoldersList(response.data);
			})
			.catch((error) => {
				console.log(error);
			});
	}

	// function used to wrap up loading
	function finishLoading() {
		// console.log("Finished loading...");
		setDashIndex(0);
	}

	// function to emit the "server:createQuestion" event
	function createQuestion(question) {
		console.log("Creating question...");
		return apiClient
			.post(
				"/v1/questions",
				{
					question: question,
				},
				{
					headers: {
						Authorization: `Bearer ${authToken}`,
					},
				}
			)
			.then((response) => {
				console.log(response);
				updateQuestionsCallback();
				return response.data;
			})
			.catch((error) => {
				console.log(error);
			});
	}

	function createQuestionInFolder(question, folders) {
		console.log("folders", folders);
		console.log("Creating question in folder...");
		return apiClient
			.post(
				"/v1/folder/question",
				{
					question: question,
					folders: folders,
				},
				{
					headers: {
						Authorization: `Bearer ${authToken}`,
					},
				}
			)
			.then((response) => {
				console.log(response);
				updateQuestionsCallback();
				updateFoldersCallback();
				return response.data;
			})
			.catch((error) => {
				console.log(error);
			});
	}

	function editQuestion(question) {
		apiClient
			.patch(
				"/v1/questions",
				{
					question: question,
				},
				{
					headers: {
						Authorization: `Bearer ${authToken}`,
					},
				}
			)
			.then((response) => {
				console.log(response);
				updateQuestionsCallback();
			})
			.catch((error) => {
				console.log(error);
			});
	}

	function deleteQuestion(questionId) {
		console.log("Deleting question...");
		apiClient
			.delete("/v1/questions", {
				data: {
					id: questionId,
				},
				headers: {
					Authorization: `Bearer ${authToken}`,
				},
			})
			.then((response) => {
				console.log(response);
				updateQuestionsCallback();
			})
			.catch((error) => {
				console.log(error);
			});
	}

	function createFolder(folder) {
		console.log("Creating folder...");
		apiClient
			.post(
				"/v1/folders",
				{
					folder: folder,
				},
				{
					headers: {
						Authorization: `Bearer ${authToken}`,
					},
				}
			)
			.then((response) => {
				updateFoldersCallback();
			})
			.catch((error) => {
				console.log(error);
			});
	}

	function editFolder(folder) {
		apiClient
			.patch(
				"/chrome/folders",
				{
					folder: folder,
				},
				{
					headers: {
						Authorization: `Bearer ${authToken}`,
					},
				}
			)
			.then((response) => {
				updateFoldersCallback();
			})
			.catch((error) => {
				console.log(error);
			});
	}

	function addQuestionToFolder(questionId, folderId) {
		apiClient
			.patch(
				"/chrome/folder/question",
				{
					question: questionId,
					folder: folderId,
				},
				{
					headers: {
						Authorization: `Bearer ${authToken}`,
					},
				}
			)
			.then((response) => {
				updateFoldersCallback();
			})
			.catch((error) => {
				console.log(error);
			});
	}

	function deleteFolder(folderId) {
		console.log("Deleting folder...");
		apiClient
			.delete("/v1/folders", {
				data: {
					id: folderId,
				},
				headers: {
					Authorization: `Bearer ${authToken}`,
				},
			})
			.then((response) => {
				updateFoldersCallback();
			})
			.catch((error) => {
				console.log(error);
			});
	}
	const getStreamerName = async () => {
		try {
			const response = await apiClient.get("/v1/getStreamerName", {
				headers: {
					Authorization: `Bearer ${localStorage.getItem("token")}`,
				},
			});

			if (response.data) {
				setStreamerName(response.data.twitch_name);
			} else {
				// setSession(response.data.session);
				setSessionLoaded(true);
			}
		} catch (error) {
			console.error("Error fetching current session", error);
		}
	};
	async function requestClientUpdate() {
		try {
			await Promise.all([
				updateLeaderboardCallback(authToken),
				updateQuestionsCallback(authToken),
				updateFoldersCallback(authToken),
				updateQuizsCallback(authToken),
				getStreamerName(),
			]);
		} catch (error) {
			console.error(error);
		} finally {
			setLoading(false);
		}
	}

	function requestRespondants(quizId, answerIndex) {
		apiClient
			.post(
				"/chrome/respondants",
				{
					id: quizId,
					answerIndex: answerIndex,
				},
				{
					headers: {
						Authorization: `Bearer ${authToken}`,
					},
				}
			)
			.then((response) => {
				console.log(response);
				setRespondants(response.data.respondants);
			})
			.catch((error) => {
				console.log(error);
			});
	}
	async function updateClient() {
		updateLeaderboardCallback(authToken);
		updateQuestionsCallback(authToken);
		updateFoldersCallback(authToken);
		updateQuizsCallback(authToken);
	}
	function startQuiz() {
		console.log("Starting quiz...");
		apiClient
			.post(
				"/chrome/quiz",
				{
					id: questionData[selectedQuestion].id,
				},
				{
					headers: {
						Authorization: `Bearer ${authToken}`,
						channel_username: currentStreamer,
					},
				}
			)
			.then((response) => {
				updateQuestionsCallback();
			})
			.catch((error) => {
				console.log(error);
			});
	}

	function endQuiz(quizData) {
		console.log(quizData);
		if (quizData.correct_answer === null) {
			console.log("Quiz doesn't have a correct answer");
			setChooseQuizData(quizData);
			setShowChooseQuiz(true);
		} else {
			console.log("Quiz has a correct answer");
			apiClient
				.patch(
					"/chrome/quiz",
					{
						quiz: quizData.quiz_object,
					},
					{
						headers: {
							Authorization: `Bearer ${authToken}`,
							channel_username: currentStreamer,
						},
					}
				)
				.then((response) => {
					setTimeout(updateQuestionsCallback, 2500);
				})
				.catch((error) => {
					console.log(error);
				});
		}
	}
	function chooseAnswerQuiz(correctAnswer) {
		console.log("Editing quiz...");
		const quizData = chooseQuizData.quiz_object;
		quizData.correct_answer = correctAnswer;
		// console.log(quizData);
		apiClient
			.put(
				"/chrome/quiz",
				{
					quiz: quizData,
				},
				{
					headers: {
						Authorization: `Bearer ${authToken}`,
						channel_username: currentStreamer,
					},
				}
			)
			.then((response) => {
				setShowChooseQuiz(false);
				setChooseQuizData(null);
				updateQuestionsCallback();
				setTimeout(updateQuestionsCallback, 1500);
				setTimeout(updateQuestionsCallback, 3000);
			})
			.catch((error) => {
				console.log(error);
			});
	}

	function cancelChooseQuiz() {
		setShowChooseQuiz(false);
		setChooseQuizData(null);
	}

	function editQuiz(quizData) {
		if (quizData == null || quizData == false) {
			setEditQuizData(false);
			return;
		}
		if (quizData) {
			setChooseQuizData(quizData);
			setEditQuizData(true);
		}
	}

	function statsClick() {
		setShowStats(true);
	}

	function homeClick() {
		setShowStats(false);
	}

	function swapQuizOrder(origin, destination) {
		console.log("Swapping quiz order");
		const newQuestionData = questionData;
		const [draggedItem] = newQuestionData.splice(origin, 1);
		newQuestionData.splice(destination, 0, draggedItem);
		newQuestionData.forEach((item, index) => {
			item.order = index + 1;
		});

		updateQuestionList(newQuestionData);
		apiClient
			.patch(
				"/chrome/questions/order",
				{
					questions: newQuestionData,
				},
				{
					headers: {
						Authorization: `Bearer ${authToken}`,
					},
				}
			)
			.then((response) => {
				updateQuestionsCallback();
			})
			.catch((error) => {
				console.log(error);
			});
	}

	function cancelQuiz(quiz) {
		console.log("Cancelling quiz...");
		apiClient
			.delete("/chrome/quiz", {
				data: {
					id: quiz.id,
				},
				headers: {
					Authorization: `Bearer ${authToken}`,
				},
			})
			.then((response) => {
				updateQuestionsCallback();
			})
			.catch((error) => {
				console.log(error);
			});
	}

	const getFolderData = () => {
		return folderData;
	};

	// if we need to show the loading screen still, return the loading component
	// console.log(session);
	// console.log(questionData);
	function attemptSocketConnection() {
		apiClient
			.post("/chrome/login", { token: parentSession })
			.then((response) => {
				if (response.data && response.data.channel_username) {
					setCurrentStreamer(response.data.channel_username);
					localStorage.setItem("token", response.data.access_token);
					setauthToken(response.data.access_token);
					setSessionLoaded(true);
				} else {
					setVerifiedStreamer(false);
				}
			})
			.catch((error) => {
				console.log("failed to connect to API", error);
				setVerifiedStreamer(false);
			});
	}

	useEffect(() => {
		const session = supabase.auth.getSession();
		// setParentSession(session);

		const { data: listener } = supabase.auth.onAuthStateChange(
			(_event, session) => {
				// console.log("authstateChange");
				console.log(session, "session");
				setParentSession(session);
			}
		);

		return () => {
			listener.subscription.unsubscribe();
		};
	}, []);

	useEffect(() => {
		if (authToken) {
			requestClientUpdate(authToken);
		}
	}, [authToken]);

	useEffect(() => {
		// setauthToken(session.access_token
		if (!parentSession) {
			setSessionLoaded(true);
			return;
		}
		attemptSocketConnection();
		console.log(parentSession, "parentSession");
		// updateLeaderboardCallback(session.access_token);
		// updateQuestionsCallback(session.access_token);
		// updateFoldersCallback(session.access_token);
		// updateQuizsCallback(session.access_token);
		finishLoading();
	}, [parentSession]);
	// useEffect(() => {
	// 	console.log(questionData);
	// }, [questionData]);

	if (parentSession == null && sessionLoaded) {
		return <Login />;
	}

	if (!verifiedStreamer) {
		return <NotVerifiedStreamerModal />;
	}
	// TODO make the dash the last case and change to have the ability to create a question anytime.
	// main dash
	if (loading) {
		return <Loading />;
	} else if (
		// Delete ! on below 2
		!questionData &&
		!questionData.length > 0 &&
		!showCreateQuestion &&
		!showChooseQuiz &&
		!editQuizData &&
		!showStats
	) {
		return (
			<ConfigDashboard
				quizData={questionData}
				deleteQuestion={deleteQuestion}
				setSelectedQuestion={SelectedQuestionSetter}
				selectedQuestion={selectedQuestion}
				startQuiz={startQuiz}
				endQuiz={endQuiz}
				moveToShowCreateQuestion={moveToShowCreateQuestion}
				editQuiz={editQuiz}
				statsClick={statsClick}
				swapQuizOrder={swapQuizOrder}
				cancelQuiz={cancelQuiz}
				homeClick={homeClick}
				createFolder={createFolder}
				folderData={getFolderData}
				deleteFolder={deleteFolder}
				editFolder={editFolder}
			/>
		);
	} else if (editQuizData) {
		return (
			<EditAnswers
				quizData={chooseQuizData}
				chooseAnswerQuiz={chooseAnswerQuiz}
				cancelChooseQuiz={cancelChooseQuiz}
				editQuiz={editQuiz}
				updateQuestion={editQuestion}
				homeClick={homeClick}
				statsClick={statsClick}
				editFolder={editFolder}
				folderData={folderData}
				addQuestionToFolder={addQuestionToFolder}
			/>
		);
	} else if (showChooseQuiz) {
		return (
			<ChooseCorrectAnswer
				quizData={chooseQuizData}
				endQuiz={endQuiz}
				chooseAnswerQuiz={chooseAnswerQuiz}
				cancelChooseQuiz={cancelChooseQuiz}
			/>
		);
	} else if (showStats) {
		return (
			<Stats
				statsClick={statsClick}
				requestClientUpdate={requestClientUpdate}
				quizData={streamQuizData}
				leaderboard={streamLeaderboard}
				homeClick={homeClick}
				requestRespondants={requestRespondants}
				respondants={respondants}
			/>
		);
	} else if (loading === false) {
		return (
			// <CreateQuestionComponent
			//   getShowCreateQuestion={getShowCreateQuestion}
			//   moveToShowCreateQuestion={moveToShowCreateQuestion}
			//   createQuestionInFolder={createQuestionInFolder}
			//   index={dashIndex}
			//   incrementSteps={DashIndexSetter}
			//   createQuestion={createQuestion}
			//   homeClick={homeClick}
			//   statsClick={statsClick}
			//   folderData={folderData}
			// />
			// <TwoFactorAuth />
			// <Stats
			//   statsClick={statsClick}
			//   requestClientUpdate={requestClientUpdate}
			//   quizData={streamQuizData}
			//   leaderboard={streamLeaderboard}
			//   homeClick={homeClick}
			//   requestRespondants={requestRespondants}
			//   respondants={respondants}
			// />
			// <ConnectAccount />
			<Dashboard
				FolderData={folderData}
				QuestionData={questionData}
				createQuestion={createQuestion}
				session={parentSession}
				createFolder={createFolder}
				deleteFolder={deleteFolder}
				deleteQuestion={deleteQuestion}
				editQuestion={editQuestion}
				editFolder={editFolder}
				createQuestionInFolder={createQuestionInFolder}
				streamerName={streamerName}
				updateClient={updateClient}
			/>
		);
	}
}
