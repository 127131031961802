import Logo from "../images/logo.png";
import PerpBar from "../images/horizontalpurp.png";

export default function Navbar(props) {
  return (
    <div>
      <div className="row navbar-background">
        <div className="col-3 navbar-logo">
          <img alt="logo" className="logo" src={Logo} />
        </div>
        <div className="col-1 navbar-headers">
          <button
            className="select-stats-button-config grayscale-img"
            onClick={() => {
              props.homeClick();
            }}
          >
            <p
              className={
                props.statsClick == true
                  ? "stats-p "
                  : "stats-p stats-p-active  "
              }
            >
              HOME
            </p>
          </button>
        </div>
        <div className="col-2 navbar-headers">
          <button
            className="select-stats-button-config grayscale-img"
            onClick={() => {
              props.statsClick();
            }}
          >
            <p
              className={
                props.statsClick == true
                  ? "stats-p "
                  : "stats-p stats-p-active  "
              }
            >
              STATISTICS
            </p>
          </button>
        </div>
      </div>
    </div>
  );
}
