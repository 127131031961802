import { ethers } from "ethers";
import React from "react";
import { Web3ModalProvider } from "./provider";

export const Web3ModalComponent = (props) => {
	const [currentAccount, setCurrentAccount] = React.useState("");

	const connectWallet = async () => {
		try {
			Web3ModalProvider.clearCachedProvider();
			const provider = await Web3ModalProvider.connect();
			let ethProvider = new ethers.providers.Web3Provider(provider);
			const accounts = await ethProvider.listAccounts();
			if (accounts) {
				setCurrentAccount(accounts[0]);
			}
		} catch (error) {
			console.log(error);
		}
	};

	const renderConnectWalletButton = () => (
		<div id="connected-button-container">
			<div
				id="connected-button"
				className="btn btn-primary connectWallet"
				onClick={connectWallet}
			>
				<div>Connect Wallet</div>
			</div>
		</div>
	);

	const renderCurrentAccount = () => (
		<div id="connected-button-container">
			<div id="connected-button">
				<div>Connected Wallet: {currentAccount}</div>
			</div>
		</div>
	);

	return (
		<div id="connected-button-grid">
			{currentAccount ? renderCurrentAccount() : renderConnectWalletButton()}
		</div>
	);
};

export default Web3ModalComponent;
