import "./App.css";
import "./frontend.css";
import "./bootstrap.min.css";

function App() {
  return (
    <div className="App">
      <h1>My React App</h1>
      <p>This is my first React app.</p>
    </div>
  );
}

export default App;
