import React, { useState, useRef, useEffect } from "react";

export default function AnswerTime(props) {
  // We need ref in this, because we are dealing
  // with JS setInterval to keep track of it and
  // stop it when needed
  const Ref = useRef(null);

  // The state for our timer
  const [timer, setTimer] = useState("loading...");

  const getTimeRemaining = (e) => {
    const total = Date.parse(e) - Date.parse(new Date());
    const seconds = Math.floor(total / 1000);
    return {
      total,
      seconds,
    };
  };

  const startTimer = (e) => {
    let { total, seconds } = getTimeRemaining(e);
    if (total >= 0) {
      // update the timer
      // check if less than 10 then we need to
      // add '0' at the beginning of the variable
      setTimer(seconds > 9 ? seconds : "0" + seconds);
    }
  };

  const clearTimer = (e) => {
    // If you adjust it you should also need to
    // adjust the Endtime formula we are about
    // to code next
    let { total, seconds } = getTimeRemaining(e);
    setTimer(seconds);

    // If you try to remove this line the
    // updating of timer Variable will be
    // after 1000ms or 1sec
    if (Ref.current) clearInterval(Ref.current);
    const id = setInterval(() => {
      startTimer(e);
    }, 1000);
    Ref.current = id;
  };

  const getDeadTime = (inputTime) => {
    const fromTime = new Date(inputTime);
    const toTime = new Date();

    const differenceTravel = fromTime.getTime() - toTime.getTime();
    if (differenceTravel < 0) {
      // return
    }
    const seconds = Math.floor(differenceTravel / 1000);

    // This is where you need to adjust if
    // you entend to add more time
    // deadline.setSeconds(deadline.getSeconds() + 10);
    return fromTime;
  };

  // We can use useEffect so that when the component
  // mount the timer will start as soon as possible

  // We put empty array to act as componentDid
  // mount only
  useEffect(() => {
    clearTimer(getDeadTime(props.endtime));
  }, [props.endtime]);

  // Another way to call the clearTimer() to start
  // the countdown is via action event from the
  // button first we create function to be called
  // by the button
  const onClickReset = () => {
    clearTimer(getDeadTime());
  };
  if (props.raw) {
    return <>TIME LEFT: {timer > 0 ? timer : "COMPLETED"}</>;
  }
  return (
    <p
      className={props.className != null ? props.className : "text-color timer"}
    >
      {/* {(timer != "loading..." && timer > 0) ? "TIME LEFT: {timer}" : "" */}
      TIME LEFT: {timer > 0 ? timer : "COMPLETED"}
    </p>
  );
}
