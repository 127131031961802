import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
	faPenToSquare,
	faCircleXmark,
	faTrash,
} from "@fortawesome/free-solid-svg-icons";
import { BsPlusLg } from "react-icons/bs";
import CreateNewGame from "./createnewGame";
import EdgeHomeNavbar from "./EdgeHomeNavbar";
import StartGame from "../images/startGameButton.png";
import AnswerPage from "./answerPage";
import QuestionMultiplierModal from "./QuestionMultiplierModal";
import CorrectQuizEnd from "./CorrectQuizEnd";
import CreateFolder from "./CreateFolder";
import EditFolder from "./EditFolder";
import DeleteFolderModal from "./DeleteFolderModal";
import EditQuestionModal from "./EditQuestionModal";
import apiClient from "../apiClient";

import DeleteQuestionModal from "./DeleteQuestionModal";

const EdgeHome = ({ selectedLimit, ...props }) => {
	const [currentFolder, setCurrentFolder] = useState(props.FolderData[0]);
	const [currentGame, setCurrentGame] = useState(null);
	const [showCreateNewGame, setShowCreateNewGame] = useState(false);
	const [selectedQuestion, setSelectedQuestion] = useState("");
	const [showAnswerPage, setShowAnswerPage] = useState(false);
	const [selectedFolder, setSelectedFolder] = useState("");
	const [currentQuestions, setCurrentQuestions] = useState([]);
	const [showMultiplierModal, setShowMultiplierModal] = useState(false);
	const [questionData, setQuestionData] = useState(null);
	const [currentQuiz, setCurrentQuiz] = useState(null);
	const [create, setCreate] = useState(true);
	const [selectedChoice, setSelectedChoice] = useState(null);
	const [showCreateFolder, setShowCreateFolder] = useState(false);
	const [showEditFolder, setShowEditFolder] = useState(false);
	const [editingFolder, setEditingFolder] = useState(null);
	const [showDeleteFolder, setShowDeleteFolder] = useState(false);
	const [deletingFolder, setDeletingFolder] = useState(null);
	const [showEditQuestion, setShowEditQuestion] = useState(false);
	const [editingQuestion, setEditingQuestion] = useState(null);
	const [showDeleteQuestion, setShowDeleteQuestion] = useState(false);
	const [deletingQuestion, setDeletingQuestion] = useState(null);

	const startCurrentGame = () => {
		setCreate(false);
		setSelectedQuestion(currentGame);
		setSelectedFolder(currentFolder);
		let questionData = {
			question: currentGame.question,
			answers: currentGame.answer_choices,
			timeToAnswer: currentGame.quiz_time,
		};
		setQuestionData(questionData);
		setShowMultiplierModal(true);
	};

	const handleCloseCorrectQuizEnd = () => {
		setCurrentQuiz(null);
	};

	const handleFolderClick = (folder) => {
		setCurrentFolder(folder);
		setCurrentGame(null);
		const selectedQuestions = getSelectedQuestions(folder.contents);
		setCurrentQuestions(selectedQuestions);
	};

	const getSelectedQuestions = (folderContent) => {
		const contentIds = Object.keys(folderContent);
		const selectedQuestions = contentIds.map((contentId) =>
			props.QuestionData.find((question) => question.id === contentId)
		);
		return selectedQuestions.filter((question) => question !== undefined);
	};

	const handleShowCreateNewGame = () => {
		setShowCreateNewGame(true);
	};

	const handleBackToCreateNewGame = () => {
		setShowCreateNewGame(true);
		setShowAnswerPage(false);
	};

	const handleCloseCreateNewGame = () => {
		setShowCreateNewGame(false);
	};

	const handleShowAnswerPage = () => {
		setShowCreateNewGame(false);
		setShowAnswerPage(true);
	};

	const handleCloseAnswerPage = () => {
		setShowAnswerPage(false);
	};
	const handleSaveAnswers = (data) => {
		setQuestionData(data);
		handleCloseAnswerPage();

		const token = localStorage.getItem("token");
		const questionPayload = {
			quiz_time: data.timeToAnswer,
			question: data.question,
			answer_choices: data.answers,
		};

		const saveQuestion = async () => {
			try {
				let response;
				if (selectedFolder) {
					response = await apiClient.post(
						"/v1/folder/question",
						{
							folders: [selectedFolder],
							question: questionPayload,
						},
						{
							headers: {
								Authorization: `Bearer ${token}`,
							},
						}
					);
				}

				if (
					response &&
					response.data &&
					response.data.question &&
					response.data.folder
				) {
					props.updateClient();
					// Update the folder with the new question in the local state
					const updatedFolderData = props.FolderData.map((folder) => {
						if (folder.name === response.data.folder) {
							return {
								...folder,
								contents: {
									...folder.contents,
									[response.data.question.id]: {
										id: response.data.question.id,
										position: 1000,
									},
								},
							};
						}
						return folder;
					});

					// Directly mutate props.FolderData for visual feedback
					props.FolderData = updatedFolderData;

					// Add the new question to props.QuestionData
					const updatedQuestionData = [
						...props.QuestionData,
						response.data.question,
					];
					props.QuestionData = updatedQuestionData;

					// Refresh the currentQuestions state with the latest data
					const updatedFolder = updatedFolderData.find(
						(folder) => folder.name === response.data.folder
					);
					if (updatedFolder) {
						handleFolderClick(updatedFolder);
					}
				} else {
					console.error("Invalid response data:", response.data);
				}
			} catch (error) {
				console.log(error);
			}
		};

		saveQuestion();
	};

	const handleCloseMultiplierModal = () => {
		setShowMultiplierModal(false);
	};

	const isFolderActive = (folder) => {
		return currentFolder === folder;
	};

	const handleSaveQuestion = (data) => {
		setSelectedQuestion(data.question);
		setSelectedFolder(data.folder);
	};

	const handleQuizCreated = (quiz) => {
		setCurrentQuiz(quiz);
		setShowMultiplierModal(false);
	};

	const handleShowCreateFolder = () => {
		setShowCreateFolder(true);
	};

	const handleCloseCreateFolder = () => {
		setShowCreateFolder(false);
	};

	const handleShowEditFolder = (folder) => {
		setEditingFolder(folder);
		setShowEditFolder(true);
	};

	const handleCloseEditFolder = () => {
		setShowEditFolder(false);
		setEditingFolder(null);
	};

	const handleSaveEditFolder = async (updatedFolder) => {
		// Implement the logic to update the folder
		// This might involve calling a function from props or making an API call
		console.log("Saving edited folder:", updatedFolder);
		// After saving, close the modal
		handleCloseEditFolder();
	};

	const handleShowDeleteFolder = (folder) => {
		setDeletingFolder(folder);
		setShowDeleteFolder(true);
	};

	const handleCloseDeleteFolder = () => {
		setShowDeleteFolder(false);
		setDeletingFolder(null);
	};

	const handleConfirmDeleteFolder = (folderId) => {
		console.log("Deleting folder:", folderId);
		console.log("currentFolder", currentFolder);
		// Assuming deleteFolder is a function that deletes the folder by its ID
		props.deleteFolder(folderId.id);

		// Check if the deleted folder is the currently selected folder
		if (currentFolder.id === folderId.id) {
			// If so, set the currentQuestions to null
			setCurrentQuestions([]);
		}
	};

	const handleShowEditQuestion = (question) => {
		setEditingQuestion(question);
		setShowEditQuestion(true);
	};

	const handleCloseEditQuestion = () => {
		setShowEditQuestion(false);
		setEditingQuestion(null);
	};

	const handleShowDeleteQuestion = (question) => {
		setDeletingQuestion(question);
		setShowDeleteQuestion(true);
	};

	const handleCloseDeleteQuestion = () => {
		setShowDeleteQuestion(false);
		setDeletingQuestion(null);
	};

	const handleConfirmDeleteQuestion = async (question) => {
		try {
			// Log the deletion attempt
			console.log("Deleting question:", question);

			// Await the deletion
			await props.deleteQuestion(question.id);

			// Filter out the question with the given ID from props.QuestionData
			const updatedQuestionData = props.QuestionData.filter(
				(q) => q.id !== question.id
			);

			// Directly update props.QuestionData for visual feedback
			props.QuestionData = updatedQuestionData;

			// Also update the current questions to reflect the change
			const updatedCurrentQuestions = currentQuestions.filter(
				(q) => q.id !== question.id
			);
			setCurrentQuestions(updatedCurrentQuestions);
		} catch (error) {
			// Handle any errors that occur during deletion
			console.error("Failed to delete question:", error);
		} finally {
			// Close the modal regardless of success or failure
			handleCloseDeleteQuestion();
		}
	};

	return (
		<div style={{ padding: "0px 20px" }}>
			<EdgeHomeNavbar selectedLimit={selectedLimit} {...props} />
			<div
				className="container-fluid"
				style={{
					backgroundColor: "#13161F",
					height: "84vh",
					padding: "0px 20px",
					overflowY: "auto",
				}}
			>
				<div className="row" style={{ borderBottom: "1px solid #515562" }}>
					<div className="col-md-4" style={{ backgroundColor: "#262931" }}>
						<div className="py-2">
							<button
								className="btn text-white d-flex align-items-center"
								onClick={handleShowCreateFolder}
							>
								<BsPlusLg style={{ marginRight: "5px" }} />
								New Folder
							</button>
						</div>
					</div>
					<div className="col-md-8" style={{ backgroundColor: "#21242D" }}>
						<div className="py-2">
							<button
								className="btn text-white d-flex align-items-center"
								onClick={handleShowCreateNewGame}
							>
								<FontAwesomeIcon
									icon={faPenToSquare}
									style={{ marginRight: "5px" }}
								/>
								New Game
							</button>
							{showCreateNewGame && (
								<CreateNewGame
									folderData={props.FolderData}
									onSave={handleSaveQuestion}
									onAnswerPage={handleShowAnswerPage}
									onClose={handleCloseCreateNewGame}
								/>
							)}
							{showAnswerPage && (
								<AnswerPage
									question={selectedQuestion}
									onSave={handleSaveAnswers}
									onClose={handleCloseAnswerPage}
									onBack={handleBackToCreateNewGame}
								/>
							)}
						</div>
					</div>
				</div>
				<div className="row flex-grow-1" style={{ minHeight: "90%" }}>
					<div
						className="col-md-4"
						style={{ backgroundColor: "#262931", overflowY: "auto" }}
					>
						{props.FolderData.map((folder) => (
							<div
								key={folder.name}
								className="folder-item row"
								style={{
									padding: "15px 20px",
									color: isFolderActive(folder) ? "#CDBB49" : "#FFFFFF",
									cursor: "pointer",
									borderBottom: "1px solid #333",
									backgroundColor: isFolderActive(folder)
										? "#1E2025"
										: "transparent",
								}}
								onClick={() => handleFolderClick(folder)}
							>
								<div className="col-8">{folder.name}</div>
								<div className="col-2">
									<button
										className="btn text-white d-flex align-items-center"
										onClick={(e) => {
											e.stopPropagation();
											handleShowEditFolder(folder);
										}}
									>
										<FontAwesomeIcon icon={faPenToSquare} />
									</button>
								</div>
								<div className="col-2 mt-1">
									<FontAwesomeIcon
										icon={faCircleXmark}
										onClick={(e) => {
											e.stopPropagation();
											handleShowDeleteFolder(folder);
										}}
										style={{ cursor: "pointer" }}
									/>
								</div>
							</div>
						))}
					</div>
					<div
						className="col-md-8"
						style={{
							backgroundColor: "#1A1D26",
							padding: "0px",
							overflowY: "auto",
							display: "flex",
						}}
					>
						{currentGame ? (
							<div className="d-flex w-100" style={{ height: "100%" }}>
								<div
									className="col-md-6"
									style={{
										borderRight: "1px solid #515562",
										height: "100%",
										overflowY: "auto",
									}}
								>
									{currentQuestions.map((question, index) => (
										<div
											key={index}
											className="game-question"
											style={{
												padding: "20px 30px",
												borderBottom: "1px solid #282b33",
												backgroundColor:
													currentGame === question ? "#21242D" : "#1A1D26",
												display: "flex",
												justifyContent: "space-between",
												alignItems: "center",
											}}
											onClick={() => setCurrentGame(question)}
										>
											<div>
												{question.question}
												<div
													style={{
														marginTop: "5px",
														color: "#67696E",
														fontSize: "15px",
													}}
												>
													{question.answer_choices.length} options
												</div>
											</div>
											<div>
												<FontAwesomeIcon
													icon={faPenToSquare}
													style={{ cursor: "pointer", marginRight: "10px" }}
													onClick={(e) => {
														e.stopPropagation();
														handleShowEditQuestion(question);
													}}
												/>
												<FontAwesomeIcon
													icon={faTrash}
													style={{ cursor: "pointer" }}
													onClick={(e) => {
														e.stopPropagation();
														handleShowDeleteQuestion(question);
													}}
												/>
											</div>
										</div>
									))}
								</div>
								<div
									style={{
										display: "flex",
										flexDirection: "column",
										height: "100%",
										width: "100%",
									}}
								>
									<div className="flex-1 overflow-y-auto p-0 mx-6">
										{currentGame.answer_choices.map((choice, index) => (
											<div key={index} className="my-2">
												<button
													className={`btn text-white w-100 text-start ${
														selectedChoice === choice ? "selected-choice" : ""
													}`}
													onClick={() => setSelectedChoice(choice)}
												>
													{choice}
												</button>
											</div>
										))}
									</div>
									<div
										style={{
											marginTop: "auto",
											borderTop: "1px solid #515562",
											padding: "0.75rem",
											display: "flex",
											justifyContent: "space-between",
											alignItems: "center",
											color: "white",
										}}
									>
										<div>Time to answer: {currentGame.quiz_time} sec</div>
										<img
											onClick={startCurrentGame}
											src={StartGame}
											alt="Start Game"
											style={{ height: "100%", cursor: "pointer" }}
										/>
									</div>
								</div>
							</div>
						) : (
							<div
								className="col-md-12"
								style={{ height: "100%", overflowY: "auto" }}
							>
								{currentQuestions.map((question, index) => (
									<div
										key={index}
										className="game-question"
										style={{
											padding: "20px 30px",
											borderBottom: "1px solid #282b33",
											backgroundColor:
												currentGame === question ? "#21242D" : "#1A1D26",
											display: "flex",
											justifyContent: "space-between",
											alignItems: "center",
										}}
										onClick={() => setCurrentGame(question)}
									>
										<div>
											{question.question}
											<div
												style={{
													marginTop: "5px",
													color: "#67696E",
													fontSize: "15px",
												}}
											>
												{question.answer_choices.length} options
											</div>
										</div>
										<div>
											<FontAwesomeIcon
												icon={faPenToSquare}
												style={{ cursor: "pointer", marginRight: "10px" }}
												onClick={(e) => {
													e.stopPropagation();
													handleShowEditQuestion(question);
												}}
											/>
											<FontAwesomeIcon
												icon={faTrash}
												style={{ cursor: "pointer" }}
												onClick={(e) => {
													e.stopPropagation();
													handleShowDeleteQuestion(question);
												}}
											/>
										</div>
									</div>
								))}
							</div>
						)}
					</div>
				</div>
			</div>

			{showMultiplierModal && (
				<QuestionMultiplierModal
					create={create}
					selectedLimit={selectedLimit}
					selectedQuestion={selectedQuestion}
					selectedFolder={selectedFolder}
					questionData={questionData}
					onQuizCreated={handleQuizCreated}
					onClose={handleCloseMultiplierModal}
					{...props}
				/>
			)}
			{currentQuiz && (
				<CorrectQuizEnd
					currentQuiz={currentQuiz}
					onClose={handleCloseCorrectQuizEnd}
				/>
			)}
			{showCreateFolder && (
				<CreateFolder
					onSave={props.createFolder}
					onClose={handleCloseCreateFolder}
				/>
			)}
			{showEditFolder && editingFolder && (
				<EditFolder
					folder={editingFolder}
					onSave={handleSaveEditFolder}
					onClose={handleCloseEditFolder}
				/>
			)}
			{showDeleteFolder && deletingFolder && (
				<DeleteFolderModal
					folder={deletingFolder}
					onConfirm={handleConfirmDeleteFolder}
					onDeny={handleCloseDeleteFolder}
				/>
			)}
			{showEditQuestion && editingQuestion && (
				<EditQuestionModal
					question={editingQuestion}
					onSave={(updatedQuestion) => {
						// Implement the logic to save the updated question
						console.log("Saving updated question:", updatedQuestion);
						props.editQuestion(updatedQuestion);
						handleCloseEditQuestion();
					}}
					onClose={handleCloseEditQuestion}
					folders={props.FolderData}
				/>
			)}

			{showDeleteQuestion && deletingQuestion && (
				<DeleteQuestionModal
					question={deletingQuestion}
					onConfirm={handleConfirmDeleteQuestion}
					onDeny={handleCloseDeleteQuestion}
				/>
			)}
		</div>
	);
};

export default EdgeHome;
