import Logo from "../images/logo.png";
import PerpBar from "../images/horizontalpurp.png";

export default function StartNavbar(props) {
  return (
    <div>
      {/* <div className="row navbar-background">
        <div className="col-3 navbar-logo">
          <img alt="logo" className="logo" src={Logo} />
        </div>
      </div>
      <img alt="perpbar" className="PerpBar" src={PerpBar} /> */}
    </div>
  );
}
