import React, { useEffect, useState } from "react";
import { ethers } from "ethers";
import { Web3ModalProvider } from "../lib/wallet"; // Ensure this path is correct
import axios from "axios";
import apiClient from "../apiClient";
import supabase from "../config/supabaseClient";

const Settings = ({ handleBackToDashboard, session }) => {
	const [userWallet, setUserWallet] = useState(session.user.wallet || "");
	const [email, setEmail] = useState(session.user.email || "");
	const [phone, setPhone] = useState("");
	const [discordUsername, setDiscordUsername] = useState("");
	const [twitterUsername, setTwitterUsername] = useState("");
	const [currentPassword, setCurrentPassword] = useState("");
	const [newPassword, setNewPassword] = useState("");

	const connectWallet = async () => {
		try {
			Web3ModalProvider.clearCachedProvider();
			const provider = await Web3ModalProvider.connect();
			let ethProvider = new ethers.providers.Web3Provider(provider);
			const accounts = await ethProvider.listAccounts();
			await apiClient.post(
				"/v1/streamerWallet",
				{
					walletAddress: accounts[0],
					email: session?.user?.email,
				},
				{
					headers: {
						Authorization: `Bearer ${localStorage.getItem("token")}`,
					},
				}
			);
			setUserWallet(accounts[0]);
		} catch (error) {
			console.error(error);
		}
	};

	const getUserWallet = async () => {
		try {
			const response = await apiClient.get("/v1/streamerWallet", {
				headers: {
					Authorization: `Bearer ${localStorage.getItem("token")}`,
				},
			});
			console.log(response.data);
			if (response.data.walletAddress) {
				setUserWallet(response.data.walletAddress);
			} else {
				console.log("No Wallet");
			}
		} catch (error) {
			console.error("Error fetching user wallet", error);
		}
	};

	const saveSettings = async () => {
		try {
			console.log(email, phone, discordUsername, twitterUsername);
			const response = await apiClient.post(
				"/v1/updateUser",
				{
					email,
					phone,
					discord_username: discordUsername,
					twitter_username: twitterUsername,
				},
				{
					headers: {
						Authorization: `Bearer ${localStorage.getItem("token")}`,
						"user-id": session.user.id,
					},
				}
			);
			console.log(response.data);
			if (response.data.success) {
				console.log("Settings saved successfully");
			} else {
				console.error("Error saving settings", response.data.error);
			}
		} catch (error) {
			console.error("Error saving settings", error);
		}
	};

	const changePassword = async () => {
		try {
			const { data, error } = await supabase.auth.updateUser({
				password: newPassword,
			});

			if (error) {
				console.error("Error changing password:", error.message);
			} else {
				console.log("Password changed successfully:", data);
			}
		} catch (error) {
			console.error("Error changing password:", error);
		}
	};

	useEffect(() => {
		if (userWallet) return;
		getUserWallet();
	}, []);
	useEffect(() => {
		const getUserDetails = async () => {
			try {
				console.log(session.user.id);
				const response = await apiClient.get("/v1/getUser", {
					headers: {
						Authorization: `Bearer ${localStorage.getItem("token")}`,
						"user-id": session.user.id,
					},
				});
				console.log(response.data);
				if (response.data.success) {
					const { email, phone, discord_username, twitter_username } =
						response.data.data;
					setEmail(email);
					setPhone(phone);
					setDiscordUsername(discord_username);
					setTwitterUsername(twitter_username);
				} else {
					console.error("Error fetching user details", response.data.error);
				}
			} catch (error) {
				console.error("Error fetching user details", error);
			}
		};

		getUserDetails();
	}, [session.user.id]); // Added dependency array to re-run the effect if session.user.id changes

	return (
		<div
			style={{
				color: "white",
				backgroundColor: "#20222A",
				padding: "40px 50px",
				borderRadius: "10px",
				width: "90%",
				margin: "20px auto",
				minHeight: "80vh",
				display: "grid",
				gridTemplateColumns: "1fr 1fr",
				gridGap: "20px",
			}}
		>
			<div
				style={{
					gridColumn: "1 / span 2",
					display: "flex",
					justifyContent: "flex-start",
					alignItems: "center",
				}}
			>
				<button
					onClick={handleBackToDashboard}
					style={{
						backgroundColor: "transparent",
						border: "none",
						color: "#ffcc00",
						fontSize: "1.2em",
						marginBottom: "20px",
					}}
				>
					&#8592; Back
				</button>
			</div>

			<div
				style={{
					gridColumn: "1 / 2",
					borderBottom: "1px solid grey",
					paddingBottom: "20px",
				}}
			>
				<div className="mb-4">
					<h3 style={{ color: "#f5f5f5" }}>Personal Information</h3>
				</div>

				<div className="row mb-3">
					<div className="col-md-6">
						<label style={{ paddingBottom: "5px" }}>Email Address</label>
						<input
							type="email"
							className="form-control"
							value={email}
							onChange={(e) => setEmail(e.target.value)}
							style={{
								backgroundColor: "#333",
								color: "#fff",
								height: "45px",
							}}
						/>
					</div>
					<div className="col-md-6">
						<label style={{ paddingBottom: "5px" }}>Phone</label>
						<input
							type="text"
							className="form-control grey-placeholder"
							placeholder="+44"
							value={phone}
							onChange={(e) => setPhone(e.target.value)}
							style={{
								backgroundColor: "#333",
								color: "#fff",
								height: "45px",
							}}
						/>
					</div>
				</div>

				<div className="row mb-3">
					<div className="col-md-6">
						<label style={{ paddingBottom: "5px" }}>Discord Username</label>
						<input
							type="text"
							className="form-control grey-placeholder"
							placeholder="Discord"
							value={discordUsername}
							onChange={(e) => setDiscordUsername(e.target.value)}
							style={{
								backgroundColor: "#333",
								color: "#fff",
								height: "45px",
							}}
						/>
					</div>
					<div className="col-md-6">
						<label style={{ paddingBottom: "5px" }}>Twitter Username</label>
						<input
							type="text"
							className="form-control grey-placeholder"
							placeholder="Twitter"
							value={twitterUsername}
							onChange={(e) => setTwitterUsername(e.target.value)}
							style={{
								backgroundColor: "#333",
								color: "#fff",
								height: "45px",
							}}
						/>
					</div>
				</div>

				<div className="text-center mb-4">
					<button className="save-settings-btn" onClick={saveSettings}>
						Save Settings
					</button>
				</div>
			</div>

			<div
				style={{
					gridColumn: "2 / 3",
					borderBottom: "1px solid grey",
					paddingBottom: "20px",
					padding: "0px 60px",
				}}
			>
				<div className="mb-4">
					<h3 style={{ color: "#f5f5f5", textAlign: "left" }}>
						Two Factor Authentication
					</h3>
					<p style={{ color: "#888", textAlign: "left" }}>
						Add an extra layer of security to your account with 2FA. You will
						need an app like Google Authenticator to enable this.
					</p>
				</div>

				<div className="mb-3">
					<label style={{ paddingBottom: "5px" }}>Password</label>
					<input
						type="password"
						className="form-control"
						value={currentPassword}
						onChange={(e) => setCurrentPassword(e.target.value)}
						style={{ backgroundColor: "#333", color: "#fff", height: "45px" }}
					/>
					<p style={{ color: "#888", textAlign: "left", marginTop: "10px" }}>
						Confirm your password to disable 2FA
					</p>
				</div>
			</div>

			<div style={{ gridColumn: "1 / 2" }}>
				<div className="mb-4">
					<h3 style={{ color: "#f5f5f5" }}>Password</h3>
				</div>

				<div className="row mb-3">
					<div className="col-md-6">
						<label style={{ paddingBottom: "5px" }}>Current Password</label>
						<input
							type="password"
							className="form-control"
							value={currentPassword}
							onChange={(e) => setCurrentPassword(e.target.value)}
							style={{
								backgroundColor: "#333",
								color: "#fff",
								height: "45px",
							}}
						/>
					</div>
					<div className="col-md-6">
						<label style={{ paddingBottom: "5px" }}>New Password</label>
						<input
							type="password"
							className="form-control"
							value={newPassword}
							onChange={(e) => setNewPassword(e.target.value)}
							style={{
								backgroundColor: "#333",
								color: "#fff",
								height: "45px",
							}}
						/>
					</div>
				</div>

				<div className="text-center mb-4">
					<button className="save-settings-btn" onClick={changePassword}>
						Save Password
					</button>
				</div>
			</div>

			<div style={{ gridColumn: "2 / 3", padding: "0px 60px" }}>
				<div className="mb-4">
					<h3 style={{ color: "#f5f5f5", textAlign: "left" }}>Connections</h3>
				</div>

				<div className="mb-3" style={{ display: "flex", alignItems: "center" }}>
					<label style={{}}>
						Wallet:{" "}
						{userWallet ? (
							<span style={{ marginLeft: "10px" }}>{`${userWallet.slice(
								0,
								4
							)}....${userWallet.slice(-4)}`}</span>
						) : (
							"Not connected"
						)}
					</label>
					<button
						className="btn btn-link"
						style={{ color: "#ffcc00", marginLeft: "5px" }}
						onClick={connectWallet}
					>
						{userWallet ? "Change Wallet" : "Connect Wallet"}
					</button>
				</div>

				<div className="mb-3" style={{ display: "flex", alignItems: "center" }}>
					<label style={{}}>Kick:</label>
					<button
						disabled
						className="btn btn-link"
						style={{ color: "#ffcc00", marginLeft: "5px" }}
					>
						Link Account
					</button>
				</div>

				{/* <div className="mb-3" style={{ display: "flex", alignItems: "center" }}>
                    <label style={{}}>Twitch:</label>
                    <button
                        className="btn btn-link"
                        style={{ color: "#ffcc00", marginLeft: "5px" }}
                    >
                        example_username (Unlink)
                    </button>
                </div> */}
			</div>
		</div>
	);
};

export default Settings;
