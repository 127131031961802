// react component
// Path: joystick-overlay/src/components/createAnswers.js
// Language: javascript
import React, { useEffect } from "react";
import SubmitAnsBtn1 from "../images/submitansbtn1.png";
import Logo from "../images/logo.png";
import BlankNavBar from "./navbarStart";
export default function ChooseCorrectAnswer(props) {
  const [correctAnswer, setCorrectAnswer] = React.useState(null);
  const [isCurrentSelected, setCurrentIsSelected] = React.useState(null);
  const [quizData, setQuizData] = React.useState(null);

  useEffect(() => {
    setQuizData(props.quizData);
  }, [props.quizData]);

  function changeCorrectAnswer(index) {
    setCorrectAnswer(index);
  }

  function clearAnswers() {
    // setAnswerChoices([{ text: "", correct: false }]);
    setCorrectAnswer(null);
  }

  function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
  }

  function submitQuestion() {
    if (correctAnswer === null) {
      return;
    }
    props.chooseAnswerQuiz(correctAnswer);
  }
  return (
    <div className="background-quiz-selection">
      <BlankNavBar />
      <div className="row">
        <div className="col-8 createanswer-scroll createanswer-scrollbar">
          <div className="row row-margin1">
            <div className="col-12">
              <p className="white-p enter-answers-p">CHOOSE CORRECT ANSWER</p>
            </div>
          </div>
          {quizData != null ? (
            quizData.answer_choices.map((answerChoice, index) => {
              return (
                <div className="row row-margin2" key={index}>
                  <div className="col-10 enter-answer-box">
                    <input
                      className="input-answer"
                      type="text"
                      placeholder="ENTER ANSWER"
                      value={answerChoice}
                      disabled={true}
                    />
                  </div>
                  <div className="col-1">
                    <div
                      onClick={() => {
                        changeCorrectAnswer(index);
                        setCurrentIsSelected(index);
                      }}
                      value={index}
                      className={classNames(
                        index == isCurrentSelected ? " type5" : "type4",
                        "circleBase col-4"
                      )}
                    >
                      {" "}
                      <div
                        className={classNames(
                          index == isCurrentSelected ? " type7" : "type1",
                          "circleBase1"
                        )}
                      ></div>
                    </div>
                  </div>
                </div>
              );
            })
          ) : (
            <div></div>
          )}
        </div>

        <div className="col-4 time-to-answer-margin">
          <div className="row row-margin1">
            <div className=" col-12 time-to-answer ">
              <p className="time-to-answer-text">TIME TO ANSWER (seconds)</p>
            </div>
          </div>
          <div className="row">
            <div className="col-12 input-time-align">
              <input
                className="input-time "
                type="text"
                value={props.quizData.quiz_time}
                // onChange={(e) => setQuizTime(e.target.value)}
                disabled={true}
              />
            </div>
          </div>
          <div className="row create-answer-btn-row">
            <div className="col-6 quiz-select-new-config quiz-selection-top-margin">
              <section
                onClick={() => {
                  props.cancelChooseQuiz();
                }}
                className="start-stop-buttons pointer-class-start left-box-polygon end-quiz-btn-section"
              >
                <p className="new-question-config-txt">BACK</p>
              </section>
            </div>
            <div className="col-6 quiz-select-new-config quiz-selection-top-margin create-quiz-btn-div">
              <section
                onClick={() => submitQuestion()}
                className="start-stop-buttons pointer-class-new green-box right-box-polygon create-move"
              >
                <p className="new-question-config-txt">SUBMIT</p>
              </section>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

{
  /* <div className="row ">
  <div className="col-12 submit-question-align">
    <button
      className="submit-button submitAnswers grayscale-img"
      onClick={() => submitQuestion()}
    >
      <img src={SubmitAnsBtn1} className="submit-ans-img" alt="submit" />
    </button>
    <div className="row">
      <div className="col-12 go-back-center">
        <p
          onClick={() => {
            props.cancelChooseQuiz();
          }}
          className="go-back-text-free"
        >
          &#60; GO BACK
        </p>
      </div>
    </div>
  </div>
</div>; */
}
