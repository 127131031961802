import React, { useState, useEffect } from "react";
import PlayEdgeButton from "../images/PlayEdge.png";
import EdgeNavbar from "./edgeNavbar";
import HistoryIcon from "../images/historyIcon.png";
import FolderIcon from "../images/folderIcon.png";
import SettingIcon from "../images/settingIcon.png";
import { FaHistory } from "react-icons/fa";
import { MdFolderCopy } from "react-icons/md";
import { BsPlus, BsPlusLg } from "react-icons/bs";
import CreateNewGame from "./createnewGame";
import CreateFolder from "./CreateFolder"; // Import CreateFolder component
import AnswerPage from "./answerPage";
import PlayEdge from "./playEdge";
import apiClient from "../apiClient";
import Settings from "./settings";
import EdgeHome from "./EdgeHome"; // Import EdgeHome component
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPenToSquare, faTrash } from "@fortawesome/free-solid-svg-icons";
import EditQuestionModal from "./EditQuestionModal";
import DeleteQuestionModal from "./DeleteQuestionModal";

const Dashboard = (props) => {
	const [activeTab, setActiveTab] = useState("HISTORY");
	const [currentFolder, setCurrentFolder] = useState(props.FolderData[0]);
	const [currentGame, setCurrentGame] = useState(null);
	const [showCreateNewGame, setShowCreateNewGame] = useState(false);
	const [showAnswerPage, setShowAnswerPage] = useState(false);
	const [showCreateFolder, setShowCreateFolder] = useState(false); // Add state for showing CreateFolder modal
	const [selectedQuestion, setSelectedQuestion] = useState("");
	const [selectedFolder, setSelectedFolder] = useState("");
	const [currentQuestions, setCurrentQuestions] = useState([]);
	const [showDashboard, setShowDashboard] = useState(true);
	const [showPlayEdgeModal, setShowPlayEdgeModal] = useState(false);
	const [session, setSession] = useState(null);
	const [sessionLoaded, setSessionLoaded] = useState(false);
	const [showSettings, setShowSettings] = useState(false);
	const [showEditQuestion, setShowEditQuestion] = useState(false);
	const [editingQuestion, setEditingQuestion] = useState(null);
	const [showDeleteQuestion, setShowDeleteQuestion] = useState(false);
	const [deletingQuestion, setDeletingQuestion] = useState(null);
	// const [streamerName, setStreamerName] = useState("");
	const handleSettingsClick = () => {
		setShowSettings(true);
		setShowDashboard(false);
	};

	const handleBackToDashboard = () => {
		setShowSettings(false);
		setShowDashboard(true);
	};

	useEffect(() => {
		const getCurrentSession = async () => {
			try {
				const response = await apiClient.get("/v1/stream_session", {
					headers: {
						Authorization: `Bearer ${localStorage.getItem("token")}`,
					},
				});
				if (response.data.session) {
					setSession(response.data.session);
					setSessionLoaded(true);
				} else {
					setSession(response.data.session);
					setSessionLoaded(true);
				}
			} catch (error) {
				console.error("Error fetching current session", error);
			}
		};

		getCurrentSession();
	}, []);

	// useEffect(() => {
	// 	const getStreamerName = async () => {
	// 		try {
	// 			const response = await apiClient.get("/v1/getStreamerName", {
	// 				headers: {
	// 					Authorization: `Bearer ${localStorage.getItem("token")}`,
	// 				},
	// 			});

	// 			if (response.data) {
	// 				setStreamerName(response.data.twitch_name);
	// 			} else {
	// 				// setSession(response.data.session);
	// 				setSessionLoaded(true);
	// 			}
	// 		} catch (error) {
	// 			console.error("Error fetching current session", error);
	// 		}
	// 	};

	// 	getStreamerName();
	// }, []);

	const historyData = [
		{
			date: "Feb 12 2024, 12:02",
			liveTime: "5h",
			players: 320,
			gamesPlayed: 4,
			earnings: "$1,900.00",
		},
		// ... more data entries
	];

	const handleShowCreateNewGame = () => {
		setShowCreateNewGame(true);
	};

	const handleCloseCreateNewGame = () => {
		setShowCreateNewGame(false);
	};

	const handleSaveQuestion = (data) => {
		setSelectedQuestion(data.question);
		setSelectedFolder(data.folder);
	};

	const handleShowAnswerPage = () => {
		setShowCreateNewGame(false);
		setShowAnswerPage(true);
	};

	const handleCloseAnswerPage = () => {
		setShowAnswerPage(false);
	};

	const handleSaveAnswers = (data) => {
		const questionPayload = {
			quiz_time: data.timeToAnswer,
			question: data.question,
			answer_choices: data.answers,
		};

		props
			.createQuestionInFolder(questionPayload, [data.selectedFolder])
			.then((question) => {
				// console.log(question, "testestest");
			});

		// handleCloseAnswerPage();
	};

	const handleCloseNewGame = () => {
		setShowCreateNewGame(false);
	};

	const onEdgePortalClick = () => {
		setShowPlayEdgeModal(true);
		setShowDashboard(false);
	};

	const handleCloseModal = () => {
		setShowPlayEdgeModal(false);
		setShowDashboard(true);
	};

	const handleBackToCreateNewGame = () => {
		setShowCreateNewGame(true);
		setShowAnswerPage(false);
	};

	const isActive = (tabName) => activeTab === tabName;

	const handleFolderClick = (folder) => {
		setCurrentFolder(folder);
		setCurrentGame(null);
		const selectedQuestions = getSelectedQuestions(folder.contents);
		setCurrentQuestions(selectedQuestions);
	};

	const getSelectedQuestions = (folderContent) => {
		const contentIds = Object.keys(folderContent);
		const selectedQuestions = contentIds.map((contentId) =>
			props.QuestionData.find((question) => question.id === contentId)
		);
		return selectedQuestions.filter((question) => question !== undefined);
	};

	const isFolderActive = (folderName) => currentFolder === folderName;

	const handleShowCreateFolder = () => {
		setShowCreateFolder(true);
	};

	const handleCloseCreateFolder = () => {
		setShowCreateFolder(false);
	};

	useEffect(() => {
		if (props.FolderData.length > 0) {
			setCurrentFolder(props.FolderData[0]);

			const selectedQuestions = getSelectedQuestions(
				props.FolderData[0].contents
			);
			setCurrentQuestions(selectedQuestions);
		}
	}, [props.FolderData]);

	const handleShowEditQuestion = (question) => {
		setEditingQuestion(question);
		setShowEditQuestion(true);
	};

	const handleCloseEditQuestion = () => {
		setShowEditQuestion(false);
		setEditingQuestion(null);
	};

	const handleShowDeleteQuestion = (question) => {
		setDeletingQuestion(question);
		setShowDeleteQuestion(true);
	};

	const handleCloseDeleteQuestion = () => {
		setShowDeleteQuestion(false);
		setDeletingQuestion(null);
	};

	const handleConfirmDeleteQuestion = (question) => {
		// Implement the logic to delete the question
		console.log("Deleting question:", question);
		props.deleteQuestion(question.id);

		// Remove the deleted question from the questions array
		const updatedQuestions = props.questions
			? props.questions.filter((q) => q.id !== question.id)
			: [];

		// Set the currentQuestions state to the updated array
		setCurrentQuestions(updatedQuestions);

		// After deleting, close the modal
		handleCloseDeleteQuestion();
	};

	return (
		<>
			<div style={{ display: showDashboard ? "block" : "none" }}>
				<EdgeNavbar streamerName={props.streamerName} />
				<div
					className="container-fluid text-light"
					style={{
						minHeight: "100vh",
						padding: "60px",
						backgroundColor: "#13161F",
					}}
				>
					<div className="row mb-4">
						<div className="col-lg-3 col-md-6 mb-3 d-flex">
							<div
								className="rounded d-flex align-items-center justify-content-start p-3 w-100"
								style={{ height: "100px", backgroundColor: "#1F222A" }}
							>
								<div>
									<div
										style={{ color: "#9c9fa2", fontSize: "12px" }}
										className=""
									>
										EARNED LAST STREAM
									</div>
									<div style={{ fontSize: "24px" }} className="h5">
										$1,900.00
									</div>
								</div>
							</div>
						</div>
						<div className="col-lg-3 col-md-6 mb-3 d-flex">
							<div
								className="rounded d-flex align-items-center justify-content-start p-3 w-100"
								style={{ height: "100px", backgroundColor: "#1F222A" }}
							>
								<div>
									<div style={{ color: "#9c9fa2", fontSize: "12px" }}>
										EARNED OWNED
									</div>
									<div style={{ fontSize: "24px" }} className="h5">
										$12,900.00
									</div>
								</div>
							</div>
						</div>
						<div className="col-lg-3 col-md-6 mb-3 d-flex">
							<div
								className="rounded d-flex align-items-center justify-content-start p-3 w-100"
								style={{ height: "100px", backgroundColor: "#1F222A" }}
							>
								<div>
									<div style={{ color: "#9c9fa2", fontSize: "12px" }}>
										EARNED ALL TIME
									</div>
									<div style={{ fontSize: "24px" }} className="h5">
										$24,900.00
									</div>
								</div>
							</div>
						</div>
						<div className="col-lg-3 col-md-6 mb-3 d-flex">
							<button
								onClick={onEdgePortalClick}
								className="btn p-0 d-flex align-items-center justify-content-center w-100"
								style={{
									background: "none",
									border: "none",
									padding: 0,
									height: "100px",
									borderRadius: "5px",
								}}
							>
								<img
									src={PlayEdgeButton}
									alt="EDGE Portal Button"
									style={{
										maxWidth: "100%",
										maxHeight: "100%",
										display: "block",
										margin: "auto",
										objectFit: "contain",
									}}
								/>
							</button>
						</div>
					</div>
					<div className="card" style={{ backgroundColor: "#1F222A" }}>
						<div className="card-body">
							<div className="d-flex justify-content-between mb-4">
								<div className="d-flex">
									<button
										onClick={() => setActiveTab("HISTORY")}
										className="btn d-flex align-items-center"
										style={{
											backgroundColor: "#2a2d35",
											padding: "6px 12px",
											marginRight: "10px",
											height: "38px",
											color: isActive("HISTORY") ? "#FCE552" : "#C7C7C9",
										}}
									>
										<FaHistory
											style={{
												marginRight: "5px",
												color: isActive("HISTORY") ? "#FCE552" : "#C7C7C9",
											}}
										/>
										HISTORY
									</button>
									<button
										onClick={() => setActiveTab("FOLDERS")}
										className="btn d-flex align-items-center"
										style={{
											backgroundColor: "#2a2d35",
											padding: "6px 12px",
											height: "38px",
											color: isActive("FOLDERS") ? "#FCE552" : "#C7C7C9",
										}}
									>
										<MdFolderCopy
											style={{
												marginRight: "5px",
												color: isActive("FOLDERS") ? "#FCE552" : "#C7C7C9",
											}}
										/>
										FOLDERS
									</button>
								</div>
								<button
									className="btn text-white d-flex align-items-center"
									style={{
										backgroundColor: "#2a2d35",
										padding: "6px 12px",
										height: "38px",
									}}
									onClick={handleSettingsClick}
								>
									<img
										src={SettingIcon}
										alt="Settings"
										style={{ height: "20px", marginRight: "8px" }}
									/>
									SETTINGS
								</button>
							</div>
							{isActive("HISTORY") && (
								<table
									style={{ height: "100px", backgroundColor: "#1F222A" }}
									className="tableBackground custom-table table-hover"
								>
									<thead
										style={{
											color: "#A5A7AA",
											marginTop: "2px",
											borderBottom: "2px solid #FFFFFF",
										}}
									>
										<tr>
											<th style={{ paddingBottom: "10px", textAlign: "left" }}>
												DATE/TIME
											</th>
											<th
												style={{ paddingBottom: "10px", textAlign: "center" }}
											>
												LIVE TIME
											</th>
											<th
												style={{ paddingBottom: "10px", textAlign: "center" }}
											>
												PLAYERS
											</th>
											<th style={{ textAlign: "center" }}>GAMES PLAYED</th>
											<th style={{ textAlign: "center" }}>EARNINGS</th>
										</tr>
									</thead>
									<tbody>
										{historyData.map((entry, index) => (
											<tr key={index} style={{ color: "#C7C7C9" }}>
												<td style={{ textAlign: "left" }}>{entry.date}</td>
												<td style={{ textAlign: "center" }}>
													{entry.liveTime}
												</td>
												<td style={{ textAlign: "center" }}>{entry.players}</td>
												<td style={{ textAlign: "center" }}>
													{entry.gamesPlayed}
												</td>
												<td style={{ textAlign: "center" }}>
													{entry.earnings}
												</td>
											</tr>
										))}
									</tbody>
								</table>
							)}

							{isActive("FOLDERS") && (
								<div
									className="d-flex"
									style={{ borderBottom: "1px solid #515562" }}
								>
									<div
										className="col-md-4"
										style={{ backgroundColor: "#262931" }}
									>
										<div className="py-2">
											<button
												className="btn text-white d-flex align-items-center"
												onClick={handleShowCreateFolder} // Add onClick handler
											>
												<BsPlusLg style={{ marginRight: "5px" }} />
												<span>New Folder</span>
											</button>
										</div>
									</div>
									<div
										className="col-md-8"
										style={{ backgroundColor: "#21242D" }}
									>
										{currentFolder && (
											<div className="d-flex">
												<div
													className="col-md-6"
													style={{
														borderRight: currentGame
															? "1px solid #515562"
															: "none",
													}}
												>
													<div className="py-2">
														<button
															className="btn text-white d-flex align-items-center"
															onClick={handleShowCreateNewGame}
														>
															<BsPlusLg style={{ marginRight: "5px" }} />
															<span>New Game</span>
														</button>
														{showCreateNewGame && (
															<CreateNewGame
																folderData={props.FolderData}
																onSave={handleSaveQuestion}
																onAnswerPage={handleShowAnswerPage}
																onClose={handleCloseCreateNewGame}
															/>
														)}
														{showAnswerPage && (
															<AnswerPage
																selectedFolder={selectedFolder}
																question={selectedQuestion}
																onSave={handleSaveAnswers}
																onClose={handleCloseAnswerPage}
																onBack={handleBackToCreateNewGame}
															/>
														)}
													</div>
												</div>
												{currentGame && (
													<div className="col-md-6 text-white d-flex align-items-center">
														<h4
															className="btn text-white"
															style={{ margin: "8px 0" }}
														>
															{currentGame.question}
														</h4>
													</div>
												)}
											</div>
										)}
									</div>
								</div>
							)}

							{isActive("FOLDERS") && (
								<div className="d-flex">
									<div
										className="col-md-4"
										style={{ backgroundColor: "#1F222A", padding: "0px" }}
									>
										<div
											className="folder-list"
											style={{
												padding: "0px",
												height: "600px",
												overflowY: "auto",
											}}
										>
											{props.FolderData.map((folder) => (
												<div
													key={folder}
													className="folder-item"
													onClick={() => handleFolderClick(folder)}
													style={{
														padding: "15px 15px",
														color: isFolderActive(folder)
															? "#CDBB49"
															: "#FFFFFF",
														background: isFolderActive(folder)
															? "linear-gradient(100deg, rgba(252, 229, 82, 0.05) 0%, rgba(252, 229, 82, 0.05) 100%)"
															: "none",
													}}
												>
													{folder.name}
												</div>
											))}
										</div>
									</div>
									<div
										className="col-md-8"
										style={{
											backgroundColor: "#1A1D26",
											padding: "0px",
											height: "600px",
											display: "flex",
										}}
									>
										{currentGame ? (
											<div className="d-flex w-100">
												<div
													className="col-md-6"
													style={{
														borderRight: "1px solid #515562",
														height: "100%",
														overflowY: "auto",
													}}
												>
													{currentQuestions.map((question, index) => (
														<div
															key={index}
															className="game-question"
															style={{
																padding: "20px 30px",
																borderBottom: "1px solid #282b33",
																backgroundColor:
																	currentGame === question
																		? "#21242D"
																		: "#1A1D26",
																display: "flex",
																justifyContent: "space-between",
																alignItems: "center",
															}}
															onClick={() => setCurrentGame(question)}
														>
															<div>
																{question.question}
																<div
																	style={{
																		marginTop: "5px",
																		color: "#67696E",
																		fontSize: "15px",
																	}}
																>
																	{question.answer_choices.length} options
																</div>
															</div>
															<div>
																<FontAwesomeIcon
																	icon={faPenToSquare}
																	style={{
																		cursor: "pointer",
																		marginRight: "10px",
																		color: "#FCE552",
																	}}
																	onClick={(e) => {
																		e.stopPropagation();
																		handleShowEditQuestion(question);
																	}}
																/>
																<FontAwesomeIcon
																	icon={faTrash}
																	style={{
																		cursor: "pointer",
																		color: "#FCE552",
																	}}
																	onClick={(e) => {
																		e.stopPropagation();
																		handleShowDeleteQuestion(question);
																	}}
																/>
															</div>
														</div>
													))}
												</div>
												<div
													className="col-md-6"
													style={{ height: "100%", overflowY: "auto" }}
												>
													<div style={{ padding: "20px 30px" }}>
														{currentGame.answer_choices.map((choice, index) => (
															<div key={index} className="my-3">
																<button className="btn text-white btn-block">
																	{choice}
																</button>
															</div>
														))}
													</div>
												</div>
											</div>
										) : (
											<div
												className="col-md-12"
												style={{ height: "100%", overflowY: "auto" }}
											>
												{currentQuestions.map((question, index) => (
													<div
														key={index}
														className="game-question"
														style={{
															padding: "20px 30px",
															borderBottom: "1px solid #282b33",
															backgroundColor:
																currentGame === question
																	? "#21242D"
																	: "#1A1D26",
															display: "flex",
															justifyContent: "space-between",
															alignItems: "center",
														}}
														onClick={() => setCurrentGame(question)}
													>
														<div>
															{question.question}
															<div
																style={{
																	marginTop: "5px",
																	color: "#67696E",
																	fontSize: "15px",
																}}
															>
																{question.answer_choices.length} options
															</div>
														</div>
														<div>
															<FontAwesomeIcon
																icon={faPenToSquare}
																style={{
																	cursor: "pointer",
																	marginRight: "10px",
																	color: "#FCE552",
																}}
																onClick={(e) => {
																	e.stopPropagation();
																	handleShowEditQuestion(question);
																}}
															/>
															<FontAwesomeIcon
																icon={faTrash}
																style={{ cursor: "pointer", color: "#FCE552" }}
																onClick={(e) => {
																	e.stopPropagation();
																	handleShowDeleteQuestion(question);
																}}
															/>
														</div>
													</div>
												))}
											</div>
										)}
									</div>
								</div>
							)}
							<div className="d-flex justify-content-center">
								<nav aria-label="Page navigation">
									<ul className="pagination">
										<li className="page-item">
											<a className="page-link text-dark" href="#">
												1
											</a>
										</li>
										<li className="page-item">
											<a className="page-link text-dark" href="#">
												2
											</a>
										</li>
										{/* ...other page numbers */}
									</ul>
								</nav>
							</div>
						</div>
					</div>
				</div>
			</div>
			{showSettings && (
				<div style={{ overflow: "auto" }}>
					<EdgeNavbar streamerName={props.streamerName} />
					<Settings
						handleBackToDashboard={handleBackToDashboard}
						session={props.session}
					/>
				</div>
			)}
			{showPlayEdgeModal && sessionLoaded && (
				<PlayEdge
					show={showPlayEdgeModal}
					onHide={handleCloseModal}
					streamerSession={session} // Pass the session as a prop
					{...props}
				/>
			)}
			{showCreateFolder && (
				<CreateFolder
					onSave={props.createFolder}
					onClose={handleCloseCreateFolder}
				/>
			)}
			{showEditQuestion && editingQuestion && (
				<EditQuestionModal
					question={editingQuestion}
					onSave={(updatedQuestion) => {
						// Implement the logic to save the updated question
						console.log("Saving updated question:", updatedQuestion);
						props.editQuestion(updatedQuestion);
						handleCloseEditQuestion();
					}}
					onClose={handleCloseEditQuestion}
					folders={props.FolderData}
				/>
			)}

			{showDeleteQuestion && deletingQuestion && (
				<DeleteQuestionModal
					question={deletingQuestion}
					onConfirm={handleConfirmDeleteQuestion}
					onDeny={handleCloseDeleteQuestion}
				/>
			)}
		</>
	);
};

export default Dashboard;
