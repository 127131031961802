import React, { useState } from "react";
import supabase from "./config/supabaseClient";
import Begin from "./Begin.png";
import EdgeIconLogin from "./images/EdgeIconLogin.png";

const SignIn = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const handleSignIn = async () => {
    try {
      const { data, error } = await supabase.auth.signInWithPassword({
        email,
        password,
      });

      if (error) {
        console.error(error);
        // Handle error appropriately
      } else {
        if (data.session !== null) {
          console.log(data);
          return data;
        }
      }
    } catch (error) {
      console.error("Error signing in:", error.message);
    }
  };

  return (
    <div className="container mt-5">
      <div className="row justify-content-center">
        <div className="col-md-4">
          {" "}
          {/* Adjusted width */}
          <div className="card card-dark-gray">
            <div className="card-body">
              <div className="text-center mb-4">
                <img src={EdgeIconLogin} alt="Edge Login" />
              </div>
              <div className="mb-3">
                <input
                  className="form-control bg-dark-blue custom-input" // Apply custom class for input background
                  placeholder="Login"
                  type="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </div>
              <div className="mb-3">
                <input
                  className="form-control bg-dark-blue custom-input" // Apply custom class for input background
                  placeholder="Password"
                  type="password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
              </div>
              <div className="d-grid gap-2">
                <button
                  className="btn btn-warning"
                  type="button"
                  onClick={handleSignIn}
                >
                  LOGIN
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SignIn;
