import React from "react";
import EdgeNav from "../images/EdgeNav.png";
import EdgeExit from "../images/EdgeExit.png";
const EdgeNavbar = (props) => {
	const username = props.streamerName;

	return (
		<nav
			style={{
				paddingLeft: "60px",
				paddingRight: "60px",
				backgroundColor: "#1316F",
			}}
			className="navbarHeight navbar navbar-expand-lg py-5"
		>
			<div className="container-fluid">
				<a className="navbar-brand" href="/">
					<img src={EdgeNav} alt="EDGE Icon" style={{ width: "103px" }} />
				</a>
				<div className="collapse navbar-collapse" id="navbarNavAltMarkup">
					<div className="navbar-nav ms-auto">
						<div
							className="nav-item d-flex align-items-center"
							style={{
								backgroundColor: "#1f222a",
								padding: "5px 30px 5px 30px",
								width: "100%", // Ensure the container stretches full width
								justifyContent: "space-between", // Adjust content spacing
							}}
						>
							<div style={{ display: "flex", alignItems: "center" }}>
								<span
									className="nav-link active"
									aria-current="page"
									style={{ color: "white" }}
								>
									{username}
								</span>
							</div>
							<img
								src={EdgeExit}
								alt="Exit"
								style={{ width: "20px", height: "20px", marginLeft: "30px" }}
							/>
						</div>
					</div>
				</div>
			</div>
		</nav>
	);
};

export default EdgeNavbar;
