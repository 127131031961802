import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import EdgeHomeMoneyLogo from "../images/EdgeHomeMoneyLogo.png";
import apiClient from "../apiClient";
import EndCancelModal from "./EndCancelModal";

function CorrectQuizEnd({ onClose, currentQuiz }) {
	const [currentPrizePool, setCurrentPrizePool] = useState(null);
	const [isEnding, setIsEnding] = useState(false);
	const [currentFinalQuiz, setCurrentFinalQuiz] = useState(null);
	const [selectedAnswer, setSelectedAnswer] = useState(null);
	const [modalType, setModalType] = useState(null);
	const token = localStorage.getItem("token");

	const getPrizePool = async () => {
		try {
			const response = await apiClient.get("/chrome/prize_pool", {
				headers: {
					Authorization: `Bearer ${token}`,
				},
				params: {
					quiz_id: currentQuiz.id,
					question_id: currentQuiz.question_id,
				},
			});
			if (response.data.prize_pool !== undefined) {
				setCurrentPrizePool(response.data.prize_pool);
			} else {
				console.error("Error: Prize pool value not found");
			}
		} catch (error) {
			console.error("Error fetching prize pool", error);
		}
	};

	// console.log(currentQuiz, "currentQuiz");

	const handleSelect = (index) => {
		setSelectedAnswer(index);
	};

	const handleEndGame = async () => {
		if (isEnding) {
			console.log("handleEndGame already running, skipping execution.");
			return; // Prevent multiple invocations
		}
		setIsEnding(true);

		console.log("endQuiz 42 - Starting handleEndGame");

		try {
			const response = await apiClient.put(
				"/v1/quiz",
				{
					quiz: currentFinalQuiz,
				},
				{
					headers: {
						Authorization: `Bearer ${token}`,
					},
				}
			);
			console.log(response, "response reapeated");

			// const patchResponse = await apiClient.patch(
			// 	"/v1/quiz",
			// 	{
			// 		quiz: currentFinalQuiz,
			// 	},
			// 	{
			// 		headers: {
			// 			Authorization: `Bearer ${token}`,
			// 		},
			// 	}
			// );
			// console.log(patchResponse, "patch response");
			onClose();
		} catch (error) {
			console.log(error, "error");
		} finally {
			console.log("endQuiz 42 - Ending handleEndGame");
			setIsEnding(false);
		}
	};

	const handleOpenEndModal = () => {
		const updatedQuiz = {
			...currentQuiz,
			correct_answer: selectedAnswer,
		};
		setCurrentFinalQuiz(updatedQuiz);
		setModalType("end");
	};

	const handleOpenCancelModal = () => {
		setModalType("cancel");
	};

	const handleCloseModal = () => {
		setModalType(null);
	};

	const handleConfirmEnd = () => {
		console.log("handleConfirmEnd - Invoked");
		handleEndGame();
		handleCloseModal();
	};

	useEffect(() => {
		setCurrentFinalQuiz(currentQuiz);
	}, [currentQuiz]);

	useEffect(() => {
		const interval = setInterval(() => {
			getPrizePool();
		}, 5000);
		return () => clearInterval(interval);
	}, []);

	return (
		<>
			{modalType && (
				<EndCancelModal
					onCancel={onClose}
					modalType={modalType}
					currentQuiz={currentFinalQuiz}
					onClose={handleCloseModal}
					handleConfirm={
						modalType === "end" ? handleConfirmEnd : handleCloseModal
					}
				/>
			)}
			{!modalType && (
				<div
					className="d-flex justify-content-center align-items-center position-fixed top-0 start-0 w-100 h-100"
					style={{ background: "rgba(0, 0, 0, 0.8)", zIndex: 1050 }}
				>
					<div
						className="text-white rounded p-4 shadow-lg"
						style={{ width: "90%", maxWidth: "40rem", background: "#20242B" }}
					>
						<div className="d-flex justify-content-between align-items-center border-bottom border-gray pb-3 mb-3">
							<h2 className="h5 mb-0">{currentQuiz.question}</h2>
							<button
								className="btn btn-close text-light"
								onClick={handleOpenCancelModal}
								style={{
									background: "none",
									border: "none",
									fontSize: "1.25rem",
								}}
							>
								✕
							</button>
						</div>
						<div className="mb-3">
							<h5 className="text-center">CHOOSE CORRECT ANSWER</h5>
							{currentQuiz.answer_choices.map((choice, index) => (
								<div
									key={index}
									className="d-flex justify-content-between align-items-center py-2 px-3"
									style={{
										background: "#272A32",
										border: "1px solid #333",
										borderLeft:
											selectedAnswer === index
												? "5px solid #FFD700"
												: "1px solid #333",
										marginBottom: "0.5rem",
										cursor: "pointer",
										transition: "all 0.3s ease",
									}}
									onMouseOver={(e) => {
										e.currentTarget.style.border = "1px solid #FFD700";
										if (selectedAnswer === index) {
											e.currentTarget.style.borderLeft = "5px solid #FFD700";
										}
									}}
									onMouseOut={(e) => {
										e.currentTarget.style.border = "1px solid #333";
										if (selectedAnswer === index) {
											e.currentTarget.style.borderLeft = "5px solid #FFD700";
										}
									}}
									onClick={() => handleSelect(index)}
								>
									{choice}
								</div>
							))}
						</div>
						<div className="d-flex justify-content-between align-items-center mt-4">
							<p
								className="mb-0 small d-flex align-items-center"
								style={{ color: "#A6A7AA" }}
							>
								Prize Pool:
								<img
									src={EdgeHomeMoneyLogo}
									alt="Money Logo"
									style={{
										height: "14px",
										marginLeft: "5px",
										marginRight: "5px",
									}}
								/>
								<span className="text-warning">
									{currentPrizePool ? currentPrizePool : 0}
								</span>
							</p>
							<button
								onClick={handleOpenEndModal}
								className="btn btn-warning text-dark"
								disabled={isEnding} // Disable button while ending
							>
								END GAME
							</button>
						</div>
					</div>
				</div>
			)}
		</>
	);
}

CorrectQuizEnd.propTypes = {
	onClose: PropTypes.func.isRequired,
	currentQuiz: PropTypes.object.isRequired,
};

export default CorrectQuizEnd;
