import React, { useEffect } from "react";
import Logo from "../images/logo.png";
import Vertperp from "../images/vertpurplebar.png";
import StartQuiz from "../images/startquiz.png";
import EndQuiz from "../images/empty-button.png";
import Xicon from "../images/x-icon.png";
import EditBtn from "../images/edit-button.png";
import Modal from "react-modal";
import ButtonHover1 from "../images/buttonhover1.png";
import Navbar from "./ConfigNavBar";
import QuizFolders from "./quizFolders";
import Plusnewquiz from "../images/plusnewquiz.png";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    backgroundColor: "#231f2e",
    width: "20%",
  },
};
export default function QuizselectionConfig(props) {
  const [activeQuizCount, setActiveQuizCount] = React.useState(0);
  const [allQuizes, setAllQuizes] = React.useState(true);
  const [selectedFolder, setSelectedFolder] = React.useState({});

  // const dummyData = [
  //   {
  //     question: "What is the best color?",
  //     answer_choices: [
  //       { answer: "Red", correct: false },
  //       { answer: "Blue", correct: false },
  //       { answer: "Green", correct: true },
  //       { answer: "Yellow", correct: false },
  //     ],
  //     active_quiz: true,
  //     id: 1,
  //   },
  // ];

  useEffect(() => {
    let _activeQuizCount = 0;
    props.quizData.map((quiz, index) => {
      if (quiz.active_quiz == true) {
        _activeQuizCount += 1;
      }
    });
    setActiveQuizCount(_activeQuizCount);
    slist(document.getElementById("sortlist"));
  }, [props.quizData]);

  useEffect(() => {
    console.log(selectedFolder);
  }, [selectedFolder]);

  const [isSelected, setIsSelected] = React.useState(null);
  function selectQuestion() {
    setIsSelected(true);
  }

  const [CancelModalIsOpen, setCancelIsOpen] = React.useState(false);
  function closeCancelModal() {
    setCancelIsOpen(false);
  }

  const [DeleteModalIsOpen, setDeleteIsOpen] = React.useState(false);
  function closeDeleteModal() {
    setDeleteIsOpen(false);
  }

  const [quizDelete, setQuizDelete] = React.useState(null);

  function changeAllQuizes(val) {
    setAllQuizes(val);
  }

  function quizGetter(folderData) {
    setSelectedFolder(folderData);
  }
  // create dummy data for folders with 1 folder and 1 quiz
  // const folderData = [
  //   {
  //     name: "Folder 1",
  //     contents: {
  //       quiz1: {
  //         name: "Quiz 1",
  //         questions: [
  //           {
  //             question: "What is 1 + 1?",
  //             answers: ["1", "2", "3", "4"],
  //             correctAnswer: "2",
  //           },
  //         ],
  //       },
  //     },
  //   },
  // ];
  function slist(target) {
    // (A) SET CSS + GET ALL LIST ITEMS
    target.classList.add("slist");
    let items = target.getElementsByTagName("li"),
      current = null;

    // (B) MAKE ITEMS DRAGGABLE + SORTABLE
    for (let i of items) {
      // (B1) ATTACH DRAGGABLE
      i.draggable = true;

      // (B2) DRAG START - YELLOW HIGHLIGHT DROPZONES
      i.ondragstart = (e) => {
        // check if the <section> under e.target has the class "quiz-select-section-config-active", if it does, we do not want to drag it
        const section = e.target.querySelector("section");
        if (section.classList.contains("quiz-select-section-config-active")) {
          e.preventDefault();
          return;
        }
        current = i;
        for (let it of items) {
          if (it != current) {
            it.classList.add("hint");
          }
        }
      };

      // (B3) DRAG ENTER - RED HIGHLIGHT DROPZONE
      i.ondragenter = (e) => {
        if (i != current) {
          i.classList.add("active");
        }
      };

      // (B4) DRAG LEAVE - REMOVE RED HIGHLIGHT
      i.ondragleave = () => i.classList.remove("active");

      // (B5) DRAG END - REMOVE ALL HIGHLIGHTS
      i.ondragend = (e) => {
        for (let it of items) {
          it.classList.remove("hint");
          it.classList.remove("active");
        }
      };

      // (B6) DRAG OVER - PREVENT THE DEFAULT "DROP", SO WE CAN DO OUR OWN
      i.ondragover = (e) => e.preventDefault();

      // (B7) ON DROP - DO SOMETHING
      i.ondrop = (e) => {
        e.preventDefault();
        if (i != current) {
          let currentpos = 0,
            droppedpos = 0;
          for (let it = 0; it < items.length; it++) {
            if (current == items[it]) {
              currentpos = it;
            }
            if (i == items[it]) {
              droppedpos = it;
            }
          }
          if (props.quizData[droppedpos].active_quiz == true) {
            console.log("dropped on active quiz");
            return;
          }
          // where the item is before reordering
          console.log("currentpos", currentpos);
          // where the tiem is going
          console.log("droppedpos", droppedpos);
          props.swapQuizOrder(currentpos, droppedpos);

          // if (currentpos < droppedpos) {
          //   // i.parentNode.insertBefore(current, i.nextSibling);
          //   // props.swapQuizOrder(currentpos, droppedpos);

          //   // props.swapQuizOrder(currentpos, droppedpos);

          // } else {
          //   // i.parentNode.insertBefore(current, i);
          //   // props.swapQuizOrder(currentpos, droppedpos);

          //   // props.swapQuizOrder(current, i);
          // }
        }
      };
    }
  }

  if (allQuizes == true) {
    return (
      <>
        <div className="overall-background">
          <div className="row">
            <Navbar homeClick={props.homeClick} statsClick={props.statsClick} />
          </div>
          <div className="row page-container">
            <div className="col-3 logo-breakpoint">
              <QuizFolders
                createFolder={props.createFolder}
                folderData={props.folderData}
                changeAllQuizes={changeAllQuizes}
                quizGetter={quizGetter}
                deleteFolder={props.deleteFolder}
                editFolder={props.editFolder}
              />
            </div>
            <div className="col-9 quiz-select-breakpoint background-quiz-selection">
              <div className="row new-question-config-box">
                <div className="col-11 create-quiz-btn-div quiz-selectrow-config quizselectconfigrow">
                  <section
                    onClick={() => {
                      props.moveToShowCreateQuestion(true);
                    }}
                    className="quiz-select-section-config new-question-config-box"
                  >
                    <p className="new-question-config-txt make-new-quiz">
                      New Quiz
                    </p>
                  </section>
                </div>
                <div className="col-1 new-question-config-txt ">
                  <img
                    alt="plusnewquiz"
                    src={Plusnewquiz}
                    className="new-quiz-img"
                  ></img>
                </div>
              </div>
              <div className="row questions-container">
                <div className="col-12 quiz-selectrow-config quizselectconfigrow">
                  <ul id="sortlist" className=" card-height">
                    {props.quizData.map((quiz, index) => {
                      return (
                        <li
                          key={index}
                          // order
                          // id=}
                          // className={classNames(
                          //   index == props.selectedQuestion ? "selected-question-dash" : "",
                          //   "col-12  quiz-selection-top-margin"
                          // )}
                          // onClick={() => {
                          //   props.setSelectedQuestion(index);
                          // }}
                        >
                          <section
                            className={classNames(
                              index == props.selectedQuestion
                                ? "quiz-select-section-config-selected"
                                : "",
                              "pointer-class quiz-select-section-config",
                              quiz.active_quiz == true &&
                                "quiz-select-section-config-active"
                            )}
                            onClick={() => {
                              props.setSelectedQuestion(index);
                              selectQuestion();
                            }}
                            // className="quiz-select-section-config pointer-class"
                          >
                            <div className="row">
                              <div className="col-md-11 col-10">
                                <p
                                  className={classNames(
                                    index == props.selectedQuestion
                                      ? "quiz-is-selected"
                                      : "",
                                    "selector-question-style"
                                  )}
                                >
                                  {quiz.question}
                                </p>
                              </div>

                              <div className="col-md-1 col-2 dltbtnwrapper">
                                {quiz.active_quiz == false ? (
                                  <p
                                    className="p-style-config-delete"
                                    onClick={() => {
                                      setQuizDelete(quiz);
                                      setDeleteIsOpen(true);
                                    }}
                                  >
                                    <img
                                      alt="xicon"
                                      className="x-icon"
                                      src={Xicon}
                                    />
                                  </p>
                                ) : (
                                  <p className="p-style-config-delete"></p>
                                )}
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-6">
                                <p className="p-style-config answers-config">
                                  {quiz.answer_choices.length} Answers
                                </p>
                              </div>
                              <div className="col-6 btnrowwrappers">
                                {quiz.active_quiz == true ? (
                                  <div>
                                    <p className="p-style-config-edit p-style-config-active">
                                      ACTIVE
                                    </p>
                                  </div>
                                ) : (
                                  <div
                                    className="editbtnwrapper"
                                    // edit quiz
                                  >
                                    <p
                                      className="p-style-config-edit"
                                      onClick={() => {
                                        props.editQuiz(quiz);
                                      }}
                                    >
                                      EDIT
                                      <img
                                        alt="editbtn"
                                        className="edit-icon"
                                        src={EditBtn}
                                      />
                                    </p>
                                  </div>
                                )}
                              </div>
                            </div>
                          </section>
                        </li>
                      );
                    })}
                  </ul>
                </div>
              </div>
              <div className="row new-quizes-buttons">
                {props.selectedQuestion != null ? (
                  props.quizData[props.selectedQuestion].active_quiz ==
                  false ? (
                    <>
                      <div className="col-6 quiz-width quiz-select-new-config quiz-selection-top-margin">
                        <section
                          onClick={() => {
                            props.endQuiz(
                              props.quizData[props.selectedQuestion]
                            );
                          }}
                          className="start-stop-buttons pointer-class-start left-box-polygon end-quiz-btn-section select-btn-disabled"
                        >
                          <p className="new-question-config-txt">END QUIZ</p>
                        </section>
                      </div>
                      <div className="col-6 quiz-width quiz-select-new-config quiz-selection-top-margin">
                        <section
                          onClick={() => {
                            props.startQuiz();
                          }}
                          className={
                            activeQuizCount >= 3
                              ? "start-stop-buttons pointer-class-start right-box-polygon create-move start-quiz-btn-section grayscale-img select-btn-disabled"
                              : "start-stop-buttons pointer-class-start right-box-polygon create-move start-quiz-btn-section grayscale-img"
                          }
                          disabled={activeQuizCount >= 3 ? true : false}
                        >
                          <p className="new-question-config-txt">START QUIZ</p>
                        </section>
                      </div>
                    </>
                  ) : (
                    <>
                      <div className="col-6 quiz-width quiz-select-new-config quiz-selection-top-margin">
                        <section
                          onClick={() => {
                            props.endQuiz(
                              props.quizData[props.selectedQuestion]
                            );
                          }}
                          className="start-stop-buttons pointer-class-start left-box-polygon end-quiz-btn-section1"
                        >
                          <p className="new-question-config-txt">END QUIZ</p>
                        </section>
                      </div>
                      <div className="col-6 quiz-width quiz-select-new-config quiz-selection-top-margin create-quiz-btn-div">
                        <section
                          onClick={() => {
                            setCancelIsOpen(true);
                          }}
                          className="start-stop-buttons pointer-class-new new-question-config-box right-box-polygon create-move"
                        >
                          <p className="new-question-config-txt">CANCEL QUIZ</p>
                        </section>
                      </div>
                    </>
                  )
                ) : (
                  // section where no quiz is currently selected
                  <></>
                )}
              </div>
            </div>
          </div>
        </div>
        <Modal
          isOpen={CancelModalIsOpen}
          style={customStyles}
          onRequestClose={() => setCancelIsOpen(false)}
        >
          <div className="modal-header">
            <div>CONFIRM CANCEL</div>
          </div>
          <hr className="hrline"></hr>
          <div className="modal-body">
            <div className="row">
              <div className="col-6 cancel-text-align">
                <button
                  className="cancel-selector btn btn-secondary"
                  onClick={() => {
                    props.cancelQuiz(props.quizData[props.selectedQuestion]);
                    setCancelIsOpen(false);
                  }}
                >
                  Yes
                </button>
              </div>
              <div className="col-6 cancel-text-align">
                <button
                  className="cancel-selector btn btn-danger "
                  onClick={() => setCancelIsOpen(false)}
                >
                  No
                </button>
              </div>
            </div>
          </div>
        </Modal>
        <Modal
          isOpen={DeleteModalIsOpen}
          style={customStyles}
          onRequestClose={() => setDeleteIsOpen(false)}
        >
          <div className="modal-header">
            <div>CONFIRM DELETE</div>
          </div>
          <hr className="hrline"></hr>
          <div className="modal-body">
            <div className="row">
              <div className="col-6 cancel-text-align">
                <button
                  onClick={(e) => {
                    e.stopPropagation();
                    props.deleteQuestion(quizDelete.id);
                    setQuizDelete(null);
                    props.setSelectedQuestion(null);
                    setDeleteIsOpen(false);
                  }}
                  className="cancel-selector btn btn-secondary"
                >
                  Yes
                </button>
              </div>
              <div className="col-6 cancel-text-align">
                <button
                  className="cancel-selector btn btn-danger "
                  onClick={() => setDeleteIsOpen(false)}
                >
                  No
                </button>
              </div>
            </div>
          </div>
        </Modal>
      </>
    );
  } else {
    if (selectedFolder != {}) {
      // we need to filter the quizData to only show the quizes in the selected folder
      // the desired quizes are stored in selectedFolder.contents.
      return (
        <>
          <div className="overall-background">
            <div className="row">
              <Navbar
                homeClick={props.homeClick}
                statsClick={props.statsClick}
              />
            </div>
            <div className="row page-container">
              <div className="col-3 logo-breakpoint">
                <QuizFolders
                  createFolder={props.createFolder}
                  folderData={props.folderData}
                  allQuizes={allQuizes}
                  changeAllQuizes={changeAllQuizes}
                  quizGetter={quizGetter}
                  deleteFolder={props.deleteFolder}
                  editFolder={props.editFolder}
                />
              </div>
              <div className="col-9 quiz-select-breakpoint background-quiz-selection">
                <div className="row new-question-config-box">
                  <div className="col-11 create-quiz-btn-div quiz-selectrow-config quizselectconfigrow">
                    <section
                      onClick={() => {
                        props.moveToShowCreateQuestion(true);
                      }}
                      className="quiz-select-section-config new-question-config-box"
                    >
                      <p className="new-question-config-txt make-new-quiz">
                        New Quiz
                      </p>
                    </section>
                  </div>
                  <div className="col-1 new-question-config-txt ">
                    <img
                      alt="plusnewquiz"
                      src={Plusnewquiz}
                      className="new-quiz-img"
                    ></img>
                  </div>
                </div>
                <div className="row">
                  <div className="col-12 quiz-selectrow-config quizselectconfigrow">
                    <ul id="sortlist" className=" card-height">
                      {props.quizData.map((quiz, index) => {
                        console.log(quiz.id);
                        console.log(Object.keys(selectedFolder.contents));
                        if (
                          Object.keys(selectedFolder.contents).includes(quiz.id)
                        ) {
                          return (
                            <li
                              key={index}
                              // order
                              // id=}
                              // className={classNames(
                              //   index == props.selectedQuestion ? "selected-question-dash" : "",
                              //   "col-12  quiz-selection-top-margin"
                              // )}
                              // onClick={() => {
                              //   props.setSelectedQuestion(index);
                              // }}
                            >
                              <section
                                className={classNames(
                                  index == props.selectedQuestion
                                    ? "quiz-select-section-config-selected"
                                    : "",
                                  "pointer-class quiz-select-section-config",
                                  quiz.active_quiz == true &&
                                    "quiz-select-section-config-active"
                                )}
                                onClick={() => {
                                  props.setSelectedQuestion(index);
                                  selectQuestion();
                                }}
                                // className="quiz-select-section-config pointer-class"
                              >
                                <div className="row">
                                  <div className="col-md-11 col-10">
                                    <p
                                      className={classNames(
                                        index == props.selectedQuestion
                                          ? "quiz-is-selected"
                                          : "",
                                        "selector-question-style"
                                      )}
                                    >
                                      {quiz.question}
                                    </p>
                                  </div>

                                  <div className="col-md-1 col-2 dltbtnwrapper">
                                    {quiz.active_quiz == false ? (
                                      <p
                                        className="p-style-config-delete"
                                        onClick={() => {
                                          setQuizDelete(quiz);
                                          setDeleteIsOpen(true);
                                        }}
                                      >
                                        <img
                                          alt="xicon"
                                          className="x-icon"
                                          src={Xicon}
                                        />
                                      </p>
                                    ) : (
                                      <p className="p-style-config-delete"></p>
                                    )}
                                  </div>
                                </div>
                                <div className="row">
                                  <div className="col-6">
                                    <p className="p-style-config answers-config">
                                      {quiz.answer_choices.length} Answers
                                    </p>
                                  </div>
                                  <div className="col-6 btnrowwrappers">
                                    {quiz.active_quiz == true ? (
                                      <div>
                                        <p className="p-style-config-edit p-style-config-active">
                                          ACTIVE
                                        </p>
                                      </div>
                                    ) : (
                                      <div
                                        className="editbtnwrapper"
                                        // edit quiz
                                      >
                                        <p
                                          className="p-style-config-edit"
                                          onClick={() => {
                                            props.editQuiz(quiz);
                                          }}
                                        >
                                          EDIT
                                          <img
                                            className="edit-icon"
                                            src={EditBtn}
                                          />
                                        </p>
                                      </div>
                                    )}
                                  </div>
                                </div>
                              </section>
                            </li>
                          );
                        }
                      })}
                    </ul>
                  </div>
                </div>
                <div className="row new-quizes-buttons">
                  {props.selectedQuestion != null ? (
                    props.quizData[props.selectedQuestion].active_quiz ==
                    false ? (
                      <>
                        <div className="col-6  quiz-select-new-config quiz-width quiz-selection-top-margin">
                          <section
                            onClick={() => {
                              props.endQuiz(
                                props.quizData[props.selectedQuestion]
                              );
                            }}
                            className="start-stop-buttons pointer-class-start left-box-polygon end-quiz-btn-section select-btn-disabled"
                          >
                            <p className="new-question-config-txt">END QUIZ</p>
                          </section>
                        </div>
                        <div className="col-6  quiz-select-new-config quiz-width quiz-selection-top-margin">
                          <section
                            onClick={() => {
                              props.startQuiz();
                            }}
                            className={
                              activeQuizCount >= 3
                                ? "start-stop-buttons pointer-class-start right-box-polygon create-move start-quiz-btn-section grayscale-img select-btn-disabled"
                                : "start-stop-buttons pointer-class-start right-box-polygon create-move start-quiz-btn-section grayscale-img"
                            }
                            disabled={activeQuizCount >= 3 ? true : false}
                          >
                            <p className="new-question-config-txt">
                              START QUIZ
                            </p>
                          </section>
                        </div>
                      </>
                    ) : (
                      <>
                        <div className="col-6 quiz-select-new-config quiz-width quiz-selection-top-margin">
                          <section
                            onClick={() => {
                              props.endQuiz(
                                props.quizData[props.selectedQuestion]
                              );
                            }}
                            className="start-stop-buttons pointer-class-start left-box-polygon end-quiz-btn-section1"
                          >
                            <p className="new-question-config-txt">END QUIZ</p>
                          </section>
                        </div>
                        <div className="col-6 quiz-select-new-config quiz-width quiz-selection-top-margin create-quiz-btn-div">
                          <section
                            onClick={() => {
                              setCancelIsOpen(true);
                            }}
                            className="start-stop-buttons pointer-class-new new-question-config-box right-box-polygon create-move"
                          >
                            <p className="new-question-config-txt">
                              CANCEL QUIZ
                            </p>
                          </section>
                        </div>
                      </>
                    )
                  ) : (
                    // section where no quiz is currently selected
                    <></>
                  )}
                </div>
              </div>
            </div>
          </div>
          <Modal
            isOpen={CancelModalIsOpen}
            style={customStyles}
            onRequestClose={() => setCancelIsOpen(false)}
          >
            <div className="modal-header">
              <div>CONFIRM CANCEL</div>
            </div>
            <hr className="hrline"></hr>
            <div className="modal-body">
              <div className="row">
                <div className="col-6 cancel-text-align">
                  <button
                    className="cancel-selector btn btn-secondary"
                    onClick={() => {
                      props.cancelQuiz(props.quizData[props.selectedQuestion]);
                      setCancelIsOpen(false);
                    }}
                  >
                    Yes
                  </button>
                </div>
                <div className="col-6 cancel-text-align">
                  <button
                    className="cancel-selector btn btn-danger "
                    onClick={() => setCancelIsOpen(false)}
                  >
                    No
                  </button>
                </div>
              </div>
            </div>
          </Modal>
          <Modal
            isOpen={DeleteModalIsOpen}
            style={customStyles}
            onRequestClose={() => setDeleteIsOpen(false)}
          >
            <div className="modal-header">
              <div>CONFIRM DELETE</div>
            </div>
            <hr className="hrline"></hr>
            <div className="modal-body">
              <div className="row">
                <div className="col-6 cancel-text-align">
                  <button
                    onClick={(e) => {
                      e.stopPropagation();
                      props.deleteQuestion(quizDelete.id);
                      setQuizDelete(null);
                      props.setSelectedQuestion(null);
                      setDeleteIsOpen(false);
                    }}
                    className="cancel-selector btn btn-secondary"
                  >
                    Yes
                  </button>
                </div>
                <div className="col-6 cancel-text-align">
                  <button
                    className="cancel-selector btn btn-danger "
                    onClick={() => setDeleteIsOpen(false)}
                  >
                    No
                  </button>
                </div>
              </div>
            </div>
          </Modal>
        </>
      );
    }
  }
}
