import React, { useState } from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { FaTimes } from "react-icons/fa";
import { RiDraggable } from "react-icons/ri";
import { IoChevronBack } from "react-icons/io5";

const AnswerPage = ({ selectedFolder, question, onSave, onClose, onBack }) => {
	const [answers, setAnswers] = useState([""]);
	const [timeToAnswer, setTimeToAnswer] = useState("");

	const handleAnswerChange = (index, value) => {
		const updatedAnswers = [...answers];
		updatedAnswers[index] = value;
		setAnswers(updatedAnswers);
	};

	const handleAddAnswer = () => {
		setAnswers([...answers, ""]);
	};

	const handleRemoveAnswer = (index) => {
		const updatedAnswers = [...answers];
		updatedAnswers.splice(index, 1);
		setAnswers(updatedAnswers);
	};

	const handleDragEnd = (result) => {
		if (!result.destination) return;

		const updatedAnswers = Array.from(answers);
		const [reorderedAnswer] = updatedAnswers.splice(result.source.index, 1);
		updatedAnswers.splice(result.destination.index, 0, reorderedAnswer);

		setAnswers(updatedAnswers);
	};

	const handleSave = () => {
		onSave({ selectedFolder, question, answers, timeToAnswer });
		onClose();
	};

	return (
		<div
			className="modal"
			tabIndex="-1"
			role="dialog"
			style={{ display: "block", backgroundColor: "rgba(0, 0, 0, 0.5)" }}
		>
			<div className="modal-dialog modal-dialog-centered" role="document">
				<div
					className="modal-content"
					style={{ backgroundColor: "#20232b", color: "#FFFFFF" }}
				>
					<div className="modal-header d-flex justify-content-between align-items-center">
						<div className="d-flex align-items-center">
							<div
								style={{
									backgroundColor: "#2B2E36",
									padding: "4px 10px",
									textAlign: "left",
									display: "flex",
									alignItems: "center",
								}}
								onClick={onBack}
							>
								<IoChevronBack
									style={{ marginRight: "5px", fontSize: "15px" }}
								/>
								{question}
							</div>
							<button
								type="button"
								className="btn btn-link text-white ml-2"
								style={{ textDecoration: "none" }}
								onClick={() => setAnswers([])}
							>
								Clear All
							</button>
						</div>
						<div
							type="button"
							className="close"
							onClick={onClose}
							aria-label="Close"
							style={{ color: "#808080" }}
						>
							<span aria-hidden="true">&times;</span>
						</div>
					</div>
					<div className="modal-body">
						<DragDropContext onDragEnd={handleDragEnd}>
							<Droppable droppableId="answers">
								{(provided) => (
									<div {...provided.droppableProps} ref={provided.innerRef}>
										{answers.map((answer, index) => (
											<Draggable
												key={index}
												draggableId={`answer-${index}`}
												index={index}
											>
												{(provided) => (
													<div
														ref={provided.innerRef}
														{...provided.draggableProps}
														style={{
															...provided.draggableProps.style,
															marginBottom: "10px",
														}}
													>
														<div className="position-relative d-flex align-items-center">
															<div
																{...provided.dragHandleProps}
																style={{
																	cursor: "grab",

																	margin: "auto",
																	justifyContent: "center",
																	alignItems: "center",
																}}
															>
																<RiDraggable style={{ fontSize: "20px" }} />
															</div>
															<input
																type="text"
																className="form-control"
																value={answer}
																onChange={(e) =>
																	handleAnswerChange(index, e.target.value)
																}
																style={{
																	backgroundColor: "#141820",
																	color: "#FFFFFF",
																	border: "none",
																	paddingRight: "30px",
																	borderRadius: "0",
																}}
															/>
															<button
																type="button"
																className="btn btn-link text-danger position-absolute"
																style={{
																	right: "5px",
																	top: "50%",
																	transform: "translateY(-50%)",
																}}
																onClick={() => handleRemoveAnswer(index)}
															>
																<FaTimes />
															</button>
														</div>
													</div>
												)}
											</Draggable>
										))}
										{provided.placeholder}
									</div>
								)}
							</Droppable>
						</DragDropContext>
						<button
							type="button"
							className="btn btn-block text-center"
							onClick={handleAddAnswer}
							style={{
								backgroundColor: "#2B2E36",
								color: "white",
								width: "100%",
								borderRadius: "0",
							}}
						>
							+ Add more
						</button>
					</div>
					<div className="modal-footer d-flex justify-content-between align-items-center">
						<div className="form-group">
							<label
								htmlFor="timeToAnswer"
								style={{ color: "#84868a", marginBottom: "5px" }}
							>
								Time to Answer
							</label>
							<div style={{ display: "flex" }}>
								<input
									type="number"
									id="timeToAnswer"
									value={timeToAnswer}
									onChange={(e) => setTimeToAnswer(e.target.value)}
									style={{
										backgroundColor: "#141820",
										color: "#FFFFFF",
										border: "none",
										width: "70px",
										borderRadius: "0",
										padding: "6px 12px",
										width: "100px",
										outline: "none",
									}}
								/>
								<div className="input-group-append">
									<span
										className="input-group-text"
										style={{
											backgroundColor: "#141820",
											color: "#797c80",
											border: "none",
											borderRadius: "0",
										}}
									>
										sec
									</span>
								</div>
							</div>
						</div>
						<button
							type="button"
							className="btn"
							onClick={handleSave}
							style={{ backgroundColor: "#F7B500", color: "#000000" }}
							disabled={
								answers.some((answer) => answer.trim() === "") ||
								timeToAnswer === ""
							}
						>
							SAVE GAME
						</button>
					</div>
				</div>
			</div>
		</div>
	);
};

export default AnswerPage;
