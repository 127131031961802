import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons"; // Import the Font Awesome X icon

const CreateFolder = ({ onSave, onClose }) => {
  const [folderName, setFolderName] = useState("");

  const handleSave = async () => {
    console.log(folderName);
    const folder = {
      name: folderName,
      contents: {},
    };
    await onSave(folder);
    await onClose();
  };

  return (
    <div
      className="modal"
      tabIndex="-1"
      role="dialog"
      style={{ display: "block", backgroundColor: "rgba(0, 0, 0, 0.5)" }}
    >
      <div className="modal-dialog modal-dialog-centered" role="document">
        <div
          className="modal-content"
          style={{
            backgroundColor: "rgb(32,35,43)",
            color: "#FFFFFF",
            borderRadius: "0",
          }}
        >
          <div
            className="modal-Create"
            style={{
              justifyContent: "flex-start",
              padding: "1rem 1.5rem",
              fontSize: "1rem",
              display: "flex",
              alignItems: "center",
            }}
          >
            <h5 className="modal-title">CREATE NEW FOLDER</h5>
            <button
              type="button"
              className="close"
              onClick={onClose}
              aria-label="Close"
              style={{
                color: "#84868a",
                marginLeft: "auto",
                background: "none",
                border: "none",
                padding: "0",
              }}
            >
              <FontAwesomeIcon icon={faTimes} size="lg" />{" "}
              {/* Use Font Awesome icon here */}
            </button>
          </div>

          <div className="modal-body">
            <div className="form-group">
              <label
                htmlFor="folderName"
                style={{
                  color: "#84868a",
                  fontSize: "12px",
                  marginBottom: "5px",
                }}
              >
                ENTER FOLDER NAME
              </label>
              <input
                type="text"
                className="form-control"
                id="folderName"
                value={folderName}
                onChange={(e) => setFolderName(e.target.value)}
                style={{
                  backgroundColor: "#141820",
                  color: "#FFFFFF",
                  border: "none",
                  borderRadius: "0",
                }}
              />
            </div>
          </div>
          <div className="modal-footer" style={{ border: "none" }}>
            <button
              type="button"
              className="btn d-flex align-items-center"
              onClick={handleSave}
              disabled={!folderName}
              style={{ backgroundColor: "transparent", color: "#fce552" }}
            >
              <span>ADD FOLDER</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreateFolder;
