import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import Liveconfig from "./liveconfig";
import "./frontend.css";
import "./bootstrap.min.css";
import "./fonts/Pixeled.ttf";

import Config from "./config";
import Login from "./supabaseLogin";
import { SessionProvider } from "./config/supabasecontext";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <SessionProvider>
      <Config />
    </SessionProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
