import React, { useState } from "react";
import { AiFillPropertySafety } from "react-icons/ai";
import supabase from "../config/supabaseClient";
import apiClient from "../apiClient";

const SelectCreditLimit = ({ onSelectLimit }) => {
	const session = supabase.auth.getSession();
	const [selectedNumber, setSelectedNumber] = useState(null);

	const handleNumberClick = (number) => {
		setSelectedNumber(number);
	};

	const handlePlayEdgeClick = () => {
		const token = localStorage.getItem("token");
		console.log(token, "token");
		apiClient
			.post(
				"/v1/stream_session",
				{ multiplier: selectedNumber },
				{
					headers: {
						Authorization: `Bearer ${token}`,
					},
				}
			)
			.then((response) => {
				console.log(response.data);
				if (response.error) {
					// Need to figure out what we're gonna do if they have an active session.
					console.log(response.error);
				}
			})
			.catch((error) => {
				console.log(error);
			});
		onSelectLimit(selectedNumber);
	};

	return (
		<div
			style={{
				backgroundColor: "#333333",
				textAlign: "center",
				padding: "30px",
			}}
		>
			<button
				style={{
					backgroundColor: "#555555",
					color: "#fff",
					border: "none",
					borderRadius: "5px",
					padding: "10px 20px",
					marginBottom: "30px",
					display: "flex",
					alignItems: "center",
					justifyContent: "center",
					fontSize: "16px",
				}}
			>
				<AiFillPropertySafety style={{ marginRight: "8px" }} /> SELECT CREDIT
				LIMIT
			</button>
			<p style={{ fontSize: "14px", marginBottom: "30px", color: "white" }}>
				There is only 1 base limit allowed per stream, it cannot be changed
				mid-stream
			</p>
			<div
				style={{
					display: "flex",
					justifyContent: "center",
					marginBottom: "20px",
				}}
			>
				{Array.from({ length: 8 }, (_, i) => {
					const number = i + 1;
					return (
						<button
							key={i}
							onClick={() => handleNumberClick(number)}
							className={`credit-button ${
								selectedNumber === number ? "selected" : ""
							}`}
							disabled={i > 4}
						>
							{number}
						</button>
					);
				})}
			</div>
			<button
				onClick={handlePlayEdgeClick}
				style={{
					backgroundColor: "#ffc107",
					color: "#333",
					border: "none",
					borderRadius: "5px",
					padding: "15px 40px",
					fontSize: "18px",
					fontWeight: "bold",
					width: "100%",
				}}
			>
				PLAY EDGE
			</button>
		</div>
	);
};

export default SelectCreditLimit;
