import React from "react";
import supabase from "../config/supabaseClient";

const NotVerifiedStreamerModal = () => {
	const handleLogout = async () => {
		const { error } = await supabase.auth.signOut();
		if (error) {
			console.error("Error logging out:", error);
		} else {
			window.location.reload(); // Reload the page after logging out
		}
	};

	return (
		<div style={modalStyles.overlay}>
			<div style={modalStyles.modal}>
				<h2>Not Verified Streamer</h2>
				<p>Please contact Joystick. You are not a verified streamer.</p>
				<button onClick={handleLogout} style={buttonStyles.logoutButton}>
					Logout
				</button>
			</div>
		</div>
	);
};

const modalStyles = {
	overlay: {
		position: "fixed",
		top: 0,
		left: 0,
		right: 0,
		bottom: 0,
		backgroundColor: "rgba(0, 0, 0, 0.5)",
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
		zIndex: 1000,
	},
	modal: {
		backgroundColor: "#fff",
		padding: "20px",
		borderRadius: "8px",
		boxShadow: "0 2px 10px rgba(0, 0, 0, 0.1)",
		textAlign: "center",
	},
};

const buttonStyles = {
	logoutButton: {
		marginTop: "20px",
		padding: "10px 20px",
		backgroundColor: "#f44336",
		color: "#fff",
		border: "none",
		borderRadius: "5px",
		cursor: "pointer",
	},
};

export default NotVerifiedStreamerModal;
