import React, { useEffect } from "react";
import Logo from "../images/logo.png";
import Vertperp from "../images/vertpurplebar.png";
import ButtonHover1 from "../images/buttonhover1.png";
import BackArrow from "../images/backarrowstats.png";
import People from "../images/statspeople.png";
import Hoticon from "../images/hoticon.png";
import Coldicon from "../images/coldicon.png";
import Navbar from "./ConfigNavBar";

export default function StatsPercent(props) {
  const [showAnswerBreakdown, setShowAnswerBreakdown] = React.useState(false);
  const [answerIndex, setAnswerIndex] = React.useState(null);
  const [leaderUUIDObj, setLeaderUUIDObj] = React.useState({});
  function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
  }

  function makeLeaderUUIDObj() {
    let leaderUUIDObj = {};
    const leaderboardType = ["daily"];
    leaderboardType.forEach((type) => {
      if (props.leaderboard[type] != null) {
        props.leaderboard[type].forEach((player) => {
          leaderUUIDObj[player.viewerUUID] = player;
        });
      }
    });
    console.log(leaderUUIDObj);
    setLeaderUUIDObj(leaderUUIDObj);
  }
  useEffect(() => {
    console.log(props.selectedQuestion);
  }, []);

  useEffect(() => {
    makeLeaderUUIDObj();
  }, [props.leaderboard]);
  return (
    <>
      <div>
        <div className="row">
          <Navbar homeClick={props.homeClick} statsClick={props.statsClick} />
        </div>
        <div className="row stats-page-container">
          <div
            className={classNames(
              showAnswerBreakdown == false
                ? "col-12 background-quiz-selection stats-percent-question-col stats-question-col-full"
                : "col-9 background-quiz-selection stats-percent-question-col stats-question-col"
            )}
          >
            <div
              className={classNames(
                showAnswerBreakdown == false
                  ? "row stats-selectrow-config stats-selectrow-config-full"
                  : "row stats-selectrow-config"
              )}
            >
              {/* put refresh button up here */}
              <div className="row qa-refresh-row">
                <div
                  className="col-10 q-a-text back-arrow"
                  onClick={() => {
                    props.closeQuestionPercent();
                  }}
                >
                  <img
                    alt="backarrow-img"
                    className="backarrow-img"
                    src={BackArrow}
                  />
                  {props.selectedQuestion.question}
                </div>
                <div
                  className="edit-refresh-btn col-2"
                  onClick={() => {
                    props.requestClientUpdate();
                  }}
                >
                  <svg
                    className="refresh-icon"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 30 30"
                    width="30px"
                    height="30px"
                  >
                    <path d="M 15 3 C 12.031398 3 9.3028202 4.0834384 7.2070312 5.875 A 1.0001 1.0001 0 1 0 8.5058594 7.3945312 C 10.25407 5.9000929 12.516602 5 15 5 C 20.19656 5 24.450989 8.9379267 24.951172 14 L 22 14 L 26 20 L 30 14 L 26.949219 14 C 26.437925 7.8516588 21.277839 3 15 3 z M 4 10 L 0 16 L 3.0507812 16 C 3.562075 22.148341 8.7221607 27 15 27 C 17.968602 27 20.69718 25.916562 22.792969 24.125 A 1.0001 1.0001 0 1 0 21.494141 22.605469 C 19.74593 24.099907 17.483398 25 15 25 C 9.80344 25 5.5490109 21.062074 5.0488281 16 L 8 16 L 4 10 z" />
                  </svg>
                </div>
              </div>
              <div className="row">
                <div className="col-12 ">
                  {props.selectedQuestion.answer_choices.map(
                    (answerChoice, index) => {
                      if (
                        props.selectedQuestion.answer_distribution
                          .answer_distribution == null
                      ) {
                        const styles = {
                          border: "#FFFFFF",
                          width: 0,
                          height: 4,
                        };
                        return (
                          <div className="row" key={index}>
                            <div className="col-lg-1 col-1">
                              <p className="percent-correct-number-txt">
                                {index + 1}
                              </p>
                            </div>
                            <div className="col-lg-6 col-3 g-0">
                              <p className="percent-correct-answers-txt">
                                {props.selectedQuestion.answer_choices[index]}
                              </p>
                            </div>
                            <div className="col-lg-4 col-7 percent-correct-wrapper g-0">
                              <p className="percent-correct-percentage">
                                ACTIVE!
                              </p>
                            </div>
                            <div className="row percent-bar-removepadding">
                              <div className="col-lg-12 bottom-bar">
                                <div className="progress-bar">
                                  <div style={styles}></div>
                                </div>
                              </div>
                            </div>
                          </div>
                        );
                      }
                      let percentChosen =
                        String(
                          Math.round(
                            (props.selectedQuestion.answer_distribution
                              .answer_distribution[index] /
                              props.selectedQuestion.answer_distribution
                                .total_answers) *
                              100
                          )
                        ) + "%";
                      if (
                        props.selectedQuestion.answer_distribution
                          .total_answers == 0
                      ) {
                        percentChosen = "0%";
                      }
                      const styles = {
                        border: "#FFFFFF",
                        width: percentChosen,
                        height: 4,
                      };
                      if (index == props.selectedQuestion.correct_answer) {
                        styles.backgroundColor = "#A237F5";
                      }
                      let rowStyles = {};
                      if (index == props.selectedQuestion.correct_answer) {
                      }
                      return (
                        <>
                          <div
                            className={
                              answerIndex == null
                                ? "row opacity-selected "
                                : answerIndex == index
                                ? "row opacity-selected"
                                : "row opacity-notselected"
                            }
                            key={index}
                            onClick={() => {
                              props.requestRespondants(
                                props.selectedQuestion.id,
                                index
                              );
                              setShowAnswerBreakdown(true);
                              setAnswerIndex(index);
                            }}
                          >
                            <div className="col-lg-1 col-1">
                              <p
                                className={
                                  props.selectedQuestion.correct_answer == index
                                    ? "percent-correct-number-txt-correct"
                                    : "percent-correct-number-txt"
                                }
                              >
                                {index + 1}
                              </p>
                            </div>
                            <div className="col-lg-6 col-3 g-0">
                              <p
                                className={
                                  props.selectedQuestion.correct_answer == index
                                    ? "percent-correct-answers-txt-correct"
                                    : "percent-correct-answers-txt"
                                }
                              >
                                {props.selectedQuestion.answer_choices[index]}
                              </p>
                            </div>
                            <div className="col-lg-4 col-7 percent-correct-wrapper g-0">
                              <p
                                className={
                                  props.selectedQuestion.correct_answer == index
                                    ? "percent-correct-percentage-correct"
                                    : "percent-correct-percentage"
                                }
                              >
                                {percentChosen} &#40;
                                {
                                  props.selectedQuestion.answer_distribution
                                    .answer_distribution[index]
                                }
                                &#41;
                              </p>
                            </div>
                            <div className="row percent-bar-removepadding">
                              <div className="col-lg-12 bottom-bar">
                                <div className="progress-bar">
                                  <div style={styles}></div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </>
                      );
                    }
                  )}
                </div>
              </div>
              <div className="row">
                <div className="col-lg-12 total-answers-stats">
                  <p className="total-stats-margin">
                    {" "}
                    <img alt="people" src={People} /> Total Votes:{" "}
                    {props.selectedQuestion.answer_distribution.total_answers}
                  </p>
                </div>
              </div>
            </div>
          </div>
          {showAnswerBreakdown == true && (
            <div className="col-3 background-leader-board">
              <div className="row ">
                <div className="row leaderboard-stats-position ">
                  <div className="col-12">
                    <div className="stats-leaderboard-section leaderboard-text stats-answer-name">
                      {props.selectedQuestion.answer_choices[answerIndex]}
                    </div>
                  </div>
                </div>
                {
                  // check if daily is in the leaderboard object and if it has any players
                  props.respondants != null && props.respondants.length > 0 ? (
                    props.respondants.map((player, index) => {
                      return (
                        <div className="row stats-leaderboard-players  ">
                          <div className="col-12 ">
                            <div
                              className="stats-leaderboard-section leaderboard-text "
                              key={index}
                            >
                              {player.viewerData.twitchName}
                            </div>
                            <div className="row">
                              <div className="col-6 stats-leaderboard-section leaderboard-text stats-player-points">
                                {leaderUUIDObj[player.viewerData.uuid] != null
                                  ? leaderUUIDObj[player.viewerData.uuid]
                                      .pointTotal
                                  : player.viewerData.currentPoints}
                              </div>
                              {leaderUUIDObj[player.viewerData.uuid] != null &&
                              leaderUUIDObj[player.viewerData.uuid]
                                .hot_streak != null &&
                              leaderUUIDObj[player.viewerData.uuid]
                                .cold_streak != null ? (
                                (leaderUUIDObj[player.viewerData.uuid]
                                  .hot_streak >= 3 && (
                                  <div className="col-4 hot-cold-wrapper">
                                    <p className="hot-streak-text">
                                      {
                                        leaderUUIDObj[player.viewerData.uuid]
                                          .hot_streak
                                      }
                                    </p>
                                    <img
                                      alt="hoticon"
                                      className="hot-cold-icon"
                                      src={Hoticon}
                                    />
                                  </div>
                                )) ||
                                (leaderUUIDObj[player.viewerData.uuid]
                                  .cold_streak >= 3 && (
                                  <div className="col-4 hot-cold-wrapper">
                                    <p className="cold-streak-text">
                                      {
                                        leaderUUIDObj[player.viewerData.uuid]
                                          .cold_streak
                                      }
                                    </p>
                                    <img
                                      alt="coldicon"
                                      className="hot-cold-icon"
                                      src={Coldicon}
                                    />
                                  </div>
                                ))
                              ) : (
                                <></>
                              )}
                            </div>
                          </div>
                        </div>
                      );
                    })
                  ) : (
                    <div className="stats-leaderboard-section">
                      No respondants to the question.
                    </div>
                  )
                }
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
}
